import { Link } from "@reach/router";
import React, { useState } from "react";
import "./LandingOnlineNavbar.css";
import tbmLogoBlanco from "../../assets/online/TBM-Logo-Blanco.svg";

export default function LandingOnlineMobileNavbar() {
  const [isVisible] = useState(true);
  const [display, setDisplay] = useState(false);

  const handleClickTab = () => {
    setDisplay(false);
  }
  

  return (
    <nav
      id="navbar-landing-online"
      className={`navbar navbar-expand-lg position-absolute navbar-light mx-auto show-mobile shadow-none vw-100 `}
      style={{
        zIndex: 1000,
      }}
    >
      <div className="container-fluid px-0 navbar-container d-flex justify-content-between align-items-center position-relative animate-fade-in-and-translate">
        <a className="navbar-brand p-0" href="/#landing">
          <img
            style={{ width: "200px", height: "20px", maxWidth: "unset" }}
            src={tbmLogoBlanco}
            className="logo-navbar"
            alt="The Body Method"
          />
        </a>
        <button
          className="navbar-toggler bg-transparent border border-light z-10 position-absolute end-0 start-0 m-auto w-max-content"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setDisplay(!display)}
        >
          <i className="fas fa-bars text-white "></i>
        </button>

        <div
          className=" top-0 w-100 vh-100 drawer-menu overflow-hidden"
          style={{
            position: "fixed",
            top: '0',
            backgroundColor: "#d1dfef",
            left: display ? 0 : "-100vw",
          }}
        >
          <div className="container-fluid px-0 h-100">
            <button
              type="button"
              className="btn bg-transparent border-0"
              onClick={() => setDisplay(false)}
            >
              <i className="fas fs-3 fa-times  position-absolute end-0 top-0 m-3"></i>
            </button>
            <ul className="navbar-nav nav justify-content-between mt-5">
              <li className="nav-item nav-item-active h-max-content">
                <a onClick={handleClickTab} className="nav-link mt-0 " href="/#about">
                  Disciplinas
                </a>
              </li>
              <li
                className={`nav-item h-max-content
                `}
              >
                <a onClick={handleClickTab} className="nav-link mt-0" href="#scrollspyMetodo">
                  Metodo
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a onClick={handleClickTab} className="nav-link mt-0" href="#scrollspyFunciones">
                  Funciones
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a onClick={handleClickTab} className="nav-link mt-0" href="#scrollspyTestimonios">
                  Testimonios
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a  className="nav-link mt-0" href="/paquetes/">
                  Paquetes
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a onClick={handleClickTab} className="nav-link mt-0" href="/rewards/">
                  Rewards
                </a>
              </li>
            </ul>
          </div>
        </div>

        <Link
          className="btn bold online-navbar__login-btn text-uppercase"
          to="/entrar"
        >
          <i className="fa fa-user"></i> <span className="ms-1">Ingresar</span>
        </Link>
      </div>
    </nav>
  );
}
