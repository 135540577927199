import React, { useContext, useState } from "react";
import SelectWeekDays from "../global/SelectWeekDays";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";

const PlannerEditDays = ({ customer_workout_plan }) => {
  const [editMode, setEditMode] = useState(false);
  const { setPropertyCustomerWorkoutPlan, saveCustomerWorkoutPlan } =
    useContext(CustomerWorkoutPlansContext);

  const toggleEditMode = () => {
    if (editMode) {
      const data = { ...customer_workout_plan };
      if(data.week_days === null) data.week_days = [...new Set(getWeekDays())];
      if(Array.isArray(data.week_days)) data.week_days = data.week_days.toString();
      saveCustomerWorkoutPlan(data);
    }
    setEditMode(!editMode);
  };

  const getWeekDays = () => {
    let week_days = [];
    if (customer_workout_plan.week_days !== null) {
      week_days = String(customer_workout_plan.week_days)
        .split(",")
        .map((day) => parseInt(day))
        .sort((a, b) => (a < b ? -1 : 1));
    } else {
      const { workout_plan_days } = customer_workout_plan.workout_plan;
      week_days = workout_plan_days.map((day) => parseInt(day.day));
    }
    return week_days;
  };

  return (
    <div className="row align-items-center mt-4">
      <div className="col-12 col-md-4 mb-2">
        <p className="mb-0">Elegiste entrenar los días:</p>
      </div>
      <div className="col-12 col-md-6 mb-2">
        <SelectWeekDays
          disabled={!editMode}
          value={getWeekDays()}
          modifier={(value) =>
            setPropertyCustomerWorkoutPlan("week_days", value)
          }
        />
      </div>
      <div className="col-12 col-md-2 mb-2 text-md-end">
        <button
          onClick={toggleEditMode}
          className="btn btn-sm btn-outline-primary"
        >
          <i className={`fa fa-${editMode ? "save" : "edit"} me-1`} />{" "}
          {editMode ? "Guardar" : "Editar"}
        </button>
      </div>
    </div>
  );
};
export default PlannerEditDays;
