import React, { useContext } from "react";
import PlannerProgram from "./PlannerProgram";
import PlannerColorLegend from "./PlannerColorLegend";
import { WorkoutPlansContext } from "../../context/WorkoutPlansContext";
import { navigate } from "@reach/router";
import PlannerEditDays from "./PlannerEditDays";

const PlannerResultSubheader = ({
  handleApply,
  workout_plan,
  customer_workout_plan,
  customer_workout_plan_id,
}) => {
  const { setWorkoutPlan } = useContext(WorkoutPlansContext);

  const renderGoal = () => {
    if (workout_plan && workout_plan !== null) {
      return (
        <div>
          <p className="bold mb-0 text-uppercase">
            {workout_plan.goal.name}: Entrena {workout_plan.frequency} veces por
            semana
          </p>
          <p>{workout_plan.goal.description}</p>
        </div>
      );
    }
  };

  const renderButtons = () => {
    if (!customer_workout_plan_id) {
      return (
        <div>
          <button onClick={handleApply} className="btn btn-primary mb-2 me-2">
            Aplicar Programa
          </button>
          <button
            onClick={() => {
              navigate("/pages/workout-planner");
              setWorkoutPlan(null);
            }}
            className="btn btn-outline-secondary mb-2"
          >
            <i className="fa fa-redo me-1" /> Empezar de Nuevo
          </button>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12 col-xl-7">
            {renderGoal()}
            <p className="bold small text-primary">
              Te recomendamos alternar el grupo muscular para obtener mejores
              resultados
            </p>
            <PlannerColorLegend />
            {customer_workout_plan !== null && (
              <PlannerEditDays customer_workout_plan={customer_workout_plan} />
            )}
          </div>
          <div className="col-12 col-xl-5">
            <PlannerProgram workout_plan={workout_plan} />
          </div>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

export default PlannerResultSubheader;
