import React, { useEffect, useState } from "react";
import "./RewardsLevels.css";
import useWindowSize from "../../hooks/useWindowSize";

const TbmIcon = ({ small }) => {
  return (
    <img
      alt="tbm logo"
      width={small ? 30 : 50}
      height={small ? 30 : 50}
      src="/logo192.png"
    />
  );
};

const MenuIcon = ({ small }) => {
  return (
    <img
      alt="menu icon"
      width={small ? 30 : 50}
      height={small ? 30 : 50}
      src="/img/menu.png"
    />
  );
};

const rewardsLevels = [
  {
    level: 1,
    name: "RISING STAR",
    description: "10% en nuestra SHOP y un recetario original gratis.",
    features: [
      { icon: (small) => <TbmIcon small={small} />, text: "10% OFF" },
      { icon: (small) => <MenuIcon small={small} />, text: "GRATIS" },
    ],
  },
  {
    level: 2,
    name: "GINDING & SHINING",
    description:
      "15% en nuestra SHOP, Acceso gratis a nuestros eventos, Recetario de juices & smoothies gratis.",
    features: [
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <MenuIcon small={small} />, text: "GRATIS" },
    ],
  },
  {
    level: 3,
    name: "FIERCE SOUL",
    description:
      "10% OFF en TBM, 15%OFF en nuestra SHOP, Acceso gratis a nuestros eventos.",
    features: [
      { icon: (small) => <TbmIcon small={small} />, text: "10% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <MenuIcon small={small} />, text: "GRATIS" },
    ],
  },
  {
    level: 4,
    name: "GIRL BOSS",
    description:
      "10% OFF en TBM, 10%OFF para friends & family, 15%OFF en nuestra SHOP, Acceso gratis a nuestros eventos.",
    features: [
      { icon: (small) => <TbmIcon small={small} />, text: "10% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "10% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "GRATIS" },
    ],
  },
  {
    level: 5,
    name: "LEGEND BABEZ",
    description:
      "15% OFF en TBM, 15%OFF para friends & family, 15%OFF en nuestra SHOP, Acceso gratis a nuestros eventos. ",
    features: [
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "GRATIS" },
    ],
  },
  {
    level: 6,
    name: "GLOW ERA",
    description:
      "Blusa Oficial de TBM gratis, 15% OFF en TBM, 15%OFF para friends & family, 15%OFF en nuestra SHOP, Acceso gratis a nuestros eventos.",
    features: [
      { icon: (small) => <TbmIcon small={small} />, text: "GRATIS" },
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "15% OFF" },
      { icon: (small) => <TbmIcon small={small} />, text: "GRATIS" },
    ],
  },
];

export default function RewardsLevels({ fluid = false, customer_milestones }) {
  const [itemsActive, setItemsActive] = useState({});

  useEffect(() => {
    rewardsLevels.forEach((level, index) => {
      const active = index === 0;
      setItemsActive((prevState) => {
        return { ...prevState, [`level-${level.level}`]: active };
      });
    });
  }, []);

  const isSmallDevice = useWindowSize(768);

  const renderStars = (amount) => {
    let current = 0;
    const stars = [];

    do {
      stars.push(
        <div
          key={`${current}-${amount}`}
          className={`${isSmallDevice ? "star__small " : "item__star"}`}
          style={{
            width: isSmallDevice ? 30 : 50,
            height: isSmallDevice ? 30 : 50,
          }}
        />
      );

      current++;
    } while (current < amount);

    return stars;
  };

  const isDisabled = (level) => {
    if (Array.isArray(customer_milestones)) {
      const hasAchievedMilestone = customer_milestones.find(
        ({ milestone_id }) => milestone_id === level.level
      );
      return !hasAchievedMilestone;
    }
  };

  const handleLevelClick = (level) => (e) => {
    if (Array.isArray(customer_milestones)) {
      const hasAchievedMilestone = customer_milestones.find(
        ({ milestone_id }) => milestone_id === level.level
      );
      if (!hasAchievedMilestone) return;
    }
    setItemsActive({
      ...itemsActive,
      [`level-${level.level}`]: e.target["aria-expanded"] === "true",
    });
  };

  const renderLevels = () => {
    return rewardsLevels.map((level, index) => {
      return (
        <div key={level.level} className={`accordion-item shadow mb-3 ${fluid ? "fluid" : ""}`}>
          <h2 className={`accordion-header`}>
            <button
              type="button"
              disabled={isDisabled(level)}
              onClick={handleLevelClick(level)}
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${level.level}`}
              aria-expanded={fluid ? false : index === 0}
              aria-controls={`collapse-${level.level}`}
              className={`accordion-button d-flex justify-content-start align-items-center`}
            >
              <h2 className={`bold item__title pe-2`}>NIVEL {level.level}</h2>
              {renderStars(level.level)}
            </button>
          </h2>
          <div
            id={`collapse-${level.level}`}
            className={`accordion-collapse collapse ${!fluid && index === 0 ? "show" : ""}`}
            data-bs-parent="#rewardsLevelsAccordion"
          >
            <div className="accordion-body">
              <p>
                {level.name}: <span>{level.description}</span>
              </p>
              <div className="d-flex">
                {level.features.map((feature, index) => (
                  <div
                    key={index}
                    className="d-flex me-3 flex-column align-items-center justify-content-center w-max-content item__feature"
                  >
                    {feature.icon(isSmallDevice)}
                    {feature.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div
      id="scrollspyLevels"
      className={`container${fluid ? "-fluid px-3 py-0" : ""} rewards-levels`}
    >
      {!fluid && (
        <div className="row">
          <div className="col-12 col-md-5 p-0 text-capitalize">
            <h3 className="levels-title mb-0">
              Descubre Nuestros Niveles de Recompensa
            </h3>
          </div>
          <div className="col-12 col-md-5 ms-auto p-0">
            <p className="levels-description mb-0">
              A medida que avanzas en nuestro programa, desbloquearás beneficios
              exclusivos y recompensas. ¡Tu esfuerzo será premiado en cada paso
              del camino!
            </p>
          </div>
        </div>
      )}

      <div className={`row ${fluid ? "" : "mt-5"}`}>
        <div className="col-12 col-md-6 px-0">
          {fluid && (
            <p className="levels-description">
              A medida que avanzas en nuestro programa, desbloquearás beneficios
              exclusivos y recompensas. ¡Tu esfuerzo será premiado en cada paso
              del camino!
            </p>
          )}
          <div className="accordion bg-white" id="rewardsLevelsAccordion">
            {renderLevels()}
          </div>
        </div>
        <div className="col-12 col-md-6 px-0">
          <div className="row justify-content-center">
            <img
              alt="tbm team"
              src="/img/rewards-image.png"
              className={`img-levels ${!fluid ? "ms-auto" : "m-auto"}`}
              style={{ width: !fluid ? "420px" : "90%" }}
            />
            {!fluid && (
              <p
                className="ms-auto mt-3 levels__image-desc"
                style={{ width: "420px" }}
              >
                Con nuestro sistema de avance, podrás medir tu progreso y
                disfrutar de beneficios.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
