import React, { useEffect, useRef, useState } from "react";
import "./LandingOnlineWorkoutPlanner.css";

import tbmPlannerImg from "../../assets/online/TBM-Planner-1.png";

export default function LandingOnlineWorkoutPlanner() {
  const [gradientPosition, setGradientPosition] = useState(88);
  const containerRef = useRef(null);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        } 
      });
    };


    // Creamos el IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    // Observamos los componentes
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const percentage = 100 - (e.clientY / window.innerHeight) * 100;
      setGradientPosition(percentage);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);
  return (
    <div id="scrollspyFunciones" className="online__workout-planner" >
      <div
        className="workout-planner__background"
        style={{
          backgroundImage: `radial-gradient(at top right,#a22749 ${gradientPosition}%,#acffff 88%)`,
        }}
      ></div>
      <div ref={containerRef} className="opacity-0">

      <h2 className="workout-planner__title online-title mb-1">
        Workout Planner
      </h2>
      <p className="workout-planner__desc ">
        {" "}
        Mide tu progreso, alcanza tus metas y gana beneficios.
      </p>

      <img alt="tbm workout planner desktop" className="workout-planner__image" src={tbmPlannerImg}/>
      </div>

    </div>
  );
}
