import React, { useCallback } from 'react'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import useEmblaCarousel from 'embla-carousel-react'
import "./OnlineTestimonialsCarousel.css";

export default function OnlineTestimonialsCarousel(props) {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop

    resetOrStop()
  }, [])

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  )

  return (
    <section className="testimonials-carousel">
      <div className="testimonials-carousel__viewport" ref={emblaRef}>
        <div className="testimonials-carousel__container">
          {slides.map((slide, index) => (
            <div className="testimonials-carousel__slide" key={index}>
              <div className="testimonials-carousel__card" style={{  }}>
                <div className="testimonials-carousel__card__profile d-flex align-items-center justify-content-start">
                  <img src={slide.userImg} alt="user image" />
                  <div className="d-flex flex-column">
                    <strong className="profile__name">{slide.userName}</strong>
                    <span className="profile__city">{slide.userCity}</span>
                  </div>
                </div>

                <p className="testimonials-carousel__card__content">
                  {slide.testimonial}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="testimonials-carousel__controls d-flex justify-content-center">
        <div className="testimonials-carousel__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'testimonials-carousel__dot'.concat(
                index === selectedIndex ? ' testimonials-carousel__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
