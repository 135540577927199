import React, { useContext, useEffect } from "react";
import VideoCard from "../components/videos/VideoCard";
import { VideosContext } from "../context/VideosContext";
import { AuthContext } from "../context/AuthContext";
import { ClassTypeContext } from "../context/ClassTypesContext";
import FilterSection from "../components/videos/FilterSection";
import { FiltersContext } from "../context/FiltersContext";
import OnlineHeader from "../components/global/OnlineHeader";
import { ProgramsContext } from "../context/ProgramsContext";
import { Link } from "@reach/router";
import CustomerUpgrade from "../components/customers/CustomerUpgrade";
import { ModalContext } from "../context/ModalContext";
import Pagination from "../components/global/Pagination";
import { PAGE_SIZE } from "../utils";

const VideoClassType = ({ class_type_id }) => {
  const filters = useContext(FiltersContext);
  const { program } = useContext(ProgramsContext);
  const { modalComponent } = useContext(ModalContext);
  const { user, getUsuario } = useContext(AuthContext);
  const { class_type, getClassType } = useContext(ClassTypeContext);

  const { setFilter, setManyFilters } = filters;

  const {
    max,
    videos,
    favoritos,
    purchase_needed,
    agregarFavorito,
    eliminarFavorito,
  } = useContext(VideosContext);

  useEffect(() => {
    getUsuario();
    getClassType(class_type_id);
    setFilter("class_type_id", class_type_id);

    if (parseInt(class_type_id) === 8) {
      setFilter("tag_id", null);
    }

    window.scrollTo({
      top: 0,
    });

    return () => {
      setManyFilters([
        { key: "page", value: 1 },
        { key: "class_type_id", value: null },
      ]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_type_id]);

  useEffect(() => {
    if (filters.class_type_id === null) {
      setFilter("class_type_id", class_type_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!user.has_access) {
      modalComponent(
        "Suscríbete a TBM",
        <CustomerUpgrade feature="Clases Online" />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getMaxPages = () => {
    if (Array.isArray(videos) && max !== null) {
      return Math.ceil(max / PAGE_SIZE);
    }
    return 1;
  };

  const getTitle = () => {
    if (class_type && class_type !== null) {
      if (program && program !== null) {
        return `${class_type.name} - ${program.name}`;
      }
      return class_type.name;
    }
  };

  const getBackUrl = () => {
    if (program && program !== null) {
      return `/mytbm/online/program/${program.url}`;
    }
  };

  const renderVideos = () => {
    if (purchase_needed) {
      return (
        <div className="container-fluid">
          <p>
            Lo sentimos, no cuentas con una membresía activa para TBM Online
          </p>
          <Link className="btn btn-primary" to="/mytbm/paquetes">
            Ir a Paquetes
          </Link>
        </div>
      );
    }
    if (Array.isArray(videos)) {
      let videosRender = videos;
      if (Array.isArray(favoritos)) {
        videosRender = favoritos;
      }
      if (videosRender.length === 0) {
        return <p>No hay videos disponibles para tu búsqueda.</p>;
      }
      return videosRender.map((video) => (
        <VideoCard
          key={video.video_id}
          video={video}
          agregarFavorito={agregarFavorito}
          eliminarFavorito={eliminarFavorito}
        />
      ));
    }
    if (!purchase_needed) return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-3 px-md-5 pb-4">
      <OnlineHeader title={getTitle()} backUrl={getBackUrl()} backEnabled />
      <FilterSection />
      <div className="row">{renderVideos()}</div>
      <Pagination
        value={filters.page}
        maxPages={getMaxPages()}
        setValue={(page) => setFilter("page", page)}
      />
    </div>
  );
};

export default VideoClassType;
