import React, { useEffect, useRef, useState } from "react";
import "./LandingOnlineBanner.css";

import img from "../../assets/online/TBM-Inicio-2.jpg";

export default function LandingOnlineBanner() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        } 
      });
    };

    const handleImageIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("image-animation");
          setTimeout(() => {{
            setImageLoaded(true);
          }}, 750);
          
        } 
      });
    };

    // Creamos el IntersectionObserver
    const containerObserver = new IntersectionObserver(handleIntersection, observerOptions);
    const imageObserver = new IntersectionObserver(handleImageIntersection, observerOptions);
    

    // Observamos los componentes
    if (containerRef.current) containerObserver.observe(containerRef.current);
    if (imageRef.current) imageObserver.observe(imageRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) containerObserver.unobserve(containerRef.current);
      if (imageRef.current) imageObserver.unobserve(imageRef.current);
    };
  }, []);

  return (
    <div ref={containerRef} className="online__banner d-flex align-items-center justify-content-between opacity-0">
      <div className="banner__content">
        <h6 className="content__tbm-name mb-1">THE BODY METHOD</h6>
        <h1 className="content__title mb-1">ENTRENA INTELIGENTE</h1>
        <p className="content_description">
          Transforma tu vida con nuestro método fitness online único y
          científicamente respaldado. Aprovecha cada segundo con nuestras
          rutinas divertidas y estratégicamente diseñadas.
        </p>

        <button type="button" className="btn online online-transparent-btn content__button">PRUÉBALO 7 DÍAS GRATIS</button>
      </div>

      <div className="banner__image-container " ref={imageRef}>
        <div className="image-overlay"></div>
        <img className=""  src={img} />
        <h3 className={`image-container__cardio ${imageLoaded ? "image-animation" : "opacity-0"}`} >CARDIO</h3>
        <h3 className={`image-container__fuerza ${imageLoaded ? "image-animation" : "opacity-0"}`} >FUERZA</h3>
        <h3 className={`image-container__barre ${imageLoaded ? "image-animation" : "opacity-0"}`} >BARRE & PILATES</h3>
      </div>
    </div>
  );
}
