import React, { useRef, useContext } from "react";
import useWorkoutPlanner from "../../hooks/useWorkoutPlanner";
import { ModalContext } from "../../context/ModalContext";
import PlannerStartEarly from "./PlannerStartEarly";
import PlannerCategory from "./PlannerCategory";
import PlannerRestart from "./PlannerRestart";
import Accordion from "../global/Accordion";
import PlannerWeek from "./PlannerWeek";
import moment from "moment";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";
import { Link } from "@reach/router";

const PlannerCalendar = ({ workout_plan, hideButtons, handleApply }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { saveCustomerWorkoutPlan } = useContext(CustomerWorkoutPlansContext);
  const wrapper = useRef(null);

  const {
    categories,
    handleDayClass,
    customer_workout_plan,
    handleClassTypeFilters,
  } = useWorkoutPlanner();

  const handleScrollLeft = () => {
    if (wrapper && wrapper !== null) {
      if (wrapper.current.scrollLeft > 0) {
        wrapper.current.scrollLeft -= 100;
      }
    }
  };

  const handleScrollRight = () => {
    if (wrapper && wrapper !== null) {
      wrapper.current.scrollLeft += 100;
    }
  };

  const handleClassTypeClick = (class_type_id) => {
    if (customer_workout_plan !== null) {
      return handleClassTypeFilters(class_type_id);
    }
    handleApply(true);
  };

  const handleRestart = () => {
    const todayString = moment().format("YYYY-MM-DD");
    const end_date = moment(todayString).add(4, "weeks").format("YYYY-MM-DD");
    saveCustomerWorkoutPlan(
      { ...customer_workout_plan, start_date: todayString, end_date },
      clearModal
    );
  };

  const handleStartEarly = (dayIndex, weekIndex) => {
    const callback = () => {
      clearModal();
      handleDayClass(weekIndex, dayIndex);
    };
    const todayString = moment().format("YYYY-MM-DD");
    const end_date = moment(todayString).add(4, "weeks").format("YYYY-MM-DD");
    saveCustomerWorkoutPlan(
      { ...customer_workout_plan, start_date: todayString, end_date },
      callback
    );
  };

  const handleClick = (weekIndex, dayIndex, dateString, dayComplete) => {
    if (customer_workout_plan === null) {
      return handleApply(() => handleDayClass(weekIndex, dayIndex));
    }
    const { start_date } = customer_workout_plan;
    const currentDate = moment().startOf("day");
    const startMomentDate = moment(customer_workout_plan.start_date).startOf(
      "day"
    );

    if (currentDate.isBefore(startMomentDate)) {
      return modalComponent(
        "¿Empezar Workout Planner?",
        <PlannerStartEarly
          start_date={start_date}
          handleStartEarly={() => handleStartEarly(dayIndex, weekIndex)}
          handleCancel={() => {
            clearModal();
            handleDayClass(weekIndex, dayIndex);
          }}
        />
      );
    }
    if (
      !dayComplete &&
      moment(dateString, "DD / MMM")
        .startOf("day")
        .isBefore(moment().startOf("day"))
    ) {
      return modalComponent(
        "¿Reestablecer Workout Planner?",
        <PlannerRestart
          start_date={start_date}
          handleCancel={clearModal}
          handleRestart={handleRestart}
        />
      );
    }
    handleDayClass(weekIndex, dayIndex);
  };

  const renderHeaders = () => {
    return (
      <div className="row bg-light p-2 border bold">
        {[
          "Semana",
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sábado",
          "Domingo",
        ].map((day) => (
          <div key={day} className="schedule-col text-center">
            {day}
          </div>
        ))}
      </div>
    );
  };

  const renderDays = () => {
    if (workout_plan && workout_plan !== null) {
      let start_date = moment().startOf("isoWeek");
      let end_date = moment()
        .startOf("isoWeek")
        .add(1, "month")
        .format("YYYY-MM-DD");
      start_date = start_date.format("YYYY-MM-DD");
      let weeksArray = [1, 2, 3, 4];
      if (customer_workout_plan && customer_workout_plan !== null) {
        start_date = customer_workout_plan.start_date;
        end_date = customer_workout_plan.end_date;
        if (moment(start_date).isValid() && moment(end_date).isValid()) {
          const total_days = moment(end_date)
            .endOf("isoWeek")
            .diff(moment(start_date).startOf("isoWeek"), "days");
          let totalWeeks = Math.ceil(total_days / 7);
          weeksArray = new Array(totalWeeks).fill(1);
        }
      }
      let originalStart = moment(start_date).format("YYYY-MM-DD");
      let currentStart = moment(start_date);
      let originalEnd = moment(end_date).format("YYYY-MM-DD");
      return (
        <div className="container-fluid px-0">
          {weeksArray.map((one, index) => {
            const weekIndex = index + 1;
            currentStart = moment(originalStart);
            currentStart = currentStart.add(index, "weeks").startOf("isoWeek");
            const currentStartString = currentStart.format("YYYY-MM-DD");
            let currentEnd = currentStart.add(1, "week").format("YYYY-MM-DD");
            return (
              <PlannerWeek
                key={weekIndex}
                end_date={currentEnd}
                weekIndex={weekIndex}
                handleClick={handleClick}
                max_end_date={originalEnd}
                workout_plan={workout_plan}
                min_start_date={originalStart}
                start_date={currentStartString}
                customer_workout_plan={customer_workout_plan}
              />
            );
          })}
        </div>
      );
    }
  };

  const renderAccordion = () => {
    if (Array.isArray(categories) && categories.length > 0) {
      return (
        <Accordion
          opened={false}
          items={categories.map((category) => ({
            title: category.name,
            children: (
              <PlannerCategory
                hideButtons={hideButtons}
                handleClick={handleClassTypeClick}
                className="col-6 col-lg-4 col-xl-3"
                class_category_id={category.class_category_id}
              />
            ),
          }))}
        />
      );
    }
  };

  return (
    <div id="planner-section" className="row mb-4">
      <div className="col-12 col-lg-12 col-xl-7 pe-0">
        <button
          onClick={handleScrollLeft}
          className="btn arrow-left text-muted show-mobile"
        >
          <i className="fa fa-chevron-left" />
        </button>
        <button
          onClick={handleScrollRight}
          className="btn arrow-right text-muted show-mobile"
        >
          <i className="fa fa-chevron-right" />
        </button>
        <div ref={wrapper} className="planner-calendar-wrapper">
          <div id="planner-calendar">
            {renderHeaders()}
            {renderDays()}
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12 col-xl-5 px-0 px-xl-3">
        {renderAccordion()}
        <Link to="/mytbm/online" className="btn btn-outline-primary mt-3">
          <i className="fa fa-chevron-left" /> Regresar a TBM Online
        </Link>
      </div>
    </div>
  );
};

export default PlannerCalendar;
