import React, { useContext, useEffect } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";

const ClassTypesCaption = () => {
  const { getClassTypes, class_types } = useContext(ClassTypeContext);

  useEffect(() => {
    getClassTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classTypesBackgrounds = {
    "G&T": "#69655D",
    BARRE: "#97B1D7",
    SNB: "#A23055",
    BBP: "#ECC1C2",
    JUMP: "#BED2D2",
    CARDIO: "#d56a5e",
    MIX: "rgb(149,137,247)",
  };

  const renderHomeClassTypes = () => {
    if (Array.isArray(class_types) && class_types.length > 0) {
      const homeClassTypes = class_types
        .filter((type) => type?.show_home)
        .sort((a, b) => a.order - b.order);

      return homeClassTypes.map((type) => {
        return (
          <div
            key={type.class_type_id}
            className="d-flex flex-column w-max-content align-items-center"
          >
            <span className="w-max-content">{type?.name}</span>
            <span
              className="badge rounded-pill mb-2"
              style={{
                backgroundColor: classTypesBackgrounds[type?.short_code],
                width: "70px",
              }}
            >
              {type?.short_code}
            </span>
          </div>
        );
      });
    }
  };

  return <div className="row">{renderHomeClassTypes()}</div>;
};

export default ClassTypesCaption;
