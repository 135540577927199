import React, { useCallback } from 'react'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import "./FeaturesCarousel.css";

export default function FeaturesCarousel(props) {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()])

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop

    resetOrStop()
  }, [])

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  )

  return (
    <section className="features-carousel">
      <div className="features-carousel__viewport" ref={emblaRef}>
        <div className="features-carousel__container">
          {slides.map((src, index) => (
            <div className="features-carousel__slide" key={index}>
              <img src={src} alt="tbm screenshots" />
            </div>
          ))}
        </div>
      </div>

      <div className="features-carousel__controls d-flex justify-content-center">
        <div className="features-carousel__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'features-carousel__dot'.concat(
                index === selectedIndex ? ' features-carousel__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
