import React, { useEffect, useRef, useState } from "react";
import "./LandingOnlineMetodo.css";

import tbmMetodo from "../../assets/online/TBM-metodo-2.png";
import tvImage from "../../assets/online/Tv-qsm06do1eaeokuw9gf8fuwwzxp6uxycowjd4gudt2o.png";

export default function LandingOnlineMetodo() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        } 
      });
    };

    const handleImageIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("image-animation");
          setTimeout(() => {{
            setImageLoaded(true);
          }}, 750);
          
        } 
      });
    };

    // Creamos el IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, observerOptions);
    const imageObserver = new IntersectionObserver(handleImageIntersection, observerOptions);

    // Observamos los componentes
    if (containerRef.current) observer.observe(containerRef.current);
    if (imageRef.current) imageObserver.observe(imageRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) observer.unobserve(containerRef.current);
      if (imageRef.current) imageObserver.unobserve(imageRef.current);
    };
  }, []);

  return (
    <div id="scrollspyMetodo" className="online__metodo position-relative opacity-0" ref={containerRef}>
      <div className="online__metodo__overlay"></div>

      <div className="metodo__content d-flex">
        <div className="content__about">
          <h6 className="about__subtitle">NUESTRO MÉTODO</h6>
          <h1 className="about__title">
            Logra un cuerpo sano y tonificado complementando diferentes
            disciplinas. Empodérate a través del movimiento
          </h1>
          <p className="about__desc">
            {" "}
            Ofrecemos un programa completo y divertido con resultados
            sostenibles
          </p>

          <button type="button" className="btn about__btn">PRUÉBALO 7 DÍAS GRATIS</button>
        </div>
        
        <div className="content__image">
        <img alt="" src={tbmMetodo} ref={imageRef}/>
          <h2 className={`image__desc ${imageLoaded ? "animate-fade-in" : "opacity-0"}`}>¿Lista para alcanzar tu máximo potencial?</h2>
        </div>
      </div>

      <img alt="" className="metodo__tv-image" src={tvImage}/>
    </div>
  );
}
