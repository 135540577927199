import React from 'react'
import './FavoritesCarousel.css';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';


export default function FavoritesCarousel(props) {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay({ delay: 2500 })])

 
  return (
    <section className="favorites-carousel">
      <div className="favorites-carousel__viewport" ref={emblaRef}>
        <div className="favorites-carousel__container">
          {slides.map((src, index) => (
            <div className="favorites-carousel__slide" key={index}>
              <img src={src} alt="tbm screenshots" />
            </div>
          ))}
        </div>
      </div>

      {/* <div className="filters-carousel__controls d-flex justify-content-center">
        <div className="filters-carousel__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'filters-carousel__dot'.concat(
                index === selectedIndex ? ' filters-carousel__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div> */}
    </section>
  )
}
