import React from "react";
import { S3_ENDPOINT } from "../../utils";
import moment from "moment";

const EstrenoCard = ({ video }) => {
  const renderFile = () => {
    const { file } = video;
    if (file && file !== null) {
      return (
        <img
          alt={video.name}
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="w-100 mw-100 br-25 mb-3 object-fit-cover"
        />
      );
    }
    return <div style={{ height: 150 }} className="bg-loading br-25 w-100 mw-100 mb-3" />;
  };
  const renderInstructor = () => {
    if (video.instructor && video.instructor !== null) {
      return (
        <p className="small text-dark">
          <i className="fa fa-user-circle me-1" /> {video.instructor.name}{" "}
          {video.instructor.last_name}
        </p>
      );
    }
  };
  return (
    <div className="estreno-card position-relative px-3">
      {renderFile()}
      <h4 className="mb-1">{video.name}</h4>
      <div className="date-overlay bg-white p-2 text-center">
        <h4 className="mb-0">
          {moment(video.available_at).utc().format("DD/MMM/YYYY")}
        </h4>
      </div>
      {renderInstructor()}
    </div>
  );
};

export default EstrenoCard;
