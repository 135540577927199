import moment from "moment";
import { Link } from "@reach/router";
import useBranch from "../hooks/useBranch";
import React, { useContext, useEffect } from "react";
import Schedule from "../components/clases/Schedule";
import { ModalContext } from "../context/ModalContext";
import { ReservationsContext } from "../context/ReservationsContext";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import { AppConfigContext } from "../context/AppConfigContext";
import MonterreySchedule from "../components/monterrey-schedule/MonterreySchedule";

const Presenciales = () => {
  const { modalComponent } = useContext(ModalContext);
  const { getMyReservations } = useContext(ReservationsContext);
  const { available_classes, getAvailableClasses } = useContext(
    AvailableClassesContext
  );
  const { studio_popup_src } = useContext(AppConfigContext);

  const { branch } = useBranch();

  useEffect(() => {
    handleRatePopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  useEffect(() => {
    if (branch && branch !== null) {
      getMyReservations({ branch_id: branch.branch_id });
      getAvailableClasses({ branch_id: branch.branch_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  const renderAvailableClasses = () => {
    if (!Array.isArray(available_classes)) {
      return <div className="spinner-border" />;
    }
    return available_classes.filter(({ start_date }) =>
      moment(start_date).local().startOf("day").isSameOrBefore(moment().local())
    ).length;
  };

  const handleRatePopup = () => {
    if (studio_popup_src && studio_popup_src !== null) {
      const currentDate = moment().format("YYYY-MM-DD");
      const lastPopup = window.localStorage.getItem("popup_presencial");
      if (lastPopup !== currentDate) {
        window.localStorage.setItem("popup_presencial", currentDate);
        return modalComponent(
          "",
          <img
            src={studio_popup_src}
            className="mw-100 w-100 d-block m-auto"
            alt="modal presencial"
          />,
          {
            no_padding: true,
            size: "xl",
          }
        );
      }
    }
  };

  const renderSchedule = () => {
    if (branch?.branch_id === 1) {
      return (
        <div className="container-fluid text-center pt-4 monterrey-schedule__container">
          <MonterreySchedule />
        </div>
      );
    }
    return <Schedule />;
  };

  return (
    <div className="container-fluid ps-md-4 mx-3 pe-5">
      <div className="row align-items-center border-bottom py-3 mb-4">
        <div className="col-12 col-md-8">
          <h1 className="mb-1">Reserva tu Clase Presencial</h1>
          <Link to="/mytbm/reservaciones" className="btn btn-primary me-2 mb-3">
            Ver Mis Reservaciones
          </Link>
          <Link to="/mytbm/waitlists" className="btn btn-outline-primary mb-3">
            Lista de Espera
          </Link>
        </div>
        <div className="col-12 mobile-left col-md-4 text-right">
          <h4 className="mb-2">Disponibles: {renderAvailableClasses()}</h4>
          <Link to="/mytbm/creditos" className="text-primary">
            Ver Vigencias
          </Link>
        </div>
      </div>
      {renderSchedule()}
    </div>
  );
};

export default Presenciales;
