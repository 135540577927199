import React from "react";
import useEmblaCarousel from "embla-carousel-react";

import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";

import "./embla.css";
import { S3_ENDPOINT } from "../../utils";

const OPTIONS = { align: "start" };

const OnlineClassTypesCarousel = ({ class_types, setCurrentClassType }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <>
      <section className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {class_types
              ?.filter((type) => type.file && type.file?.type && type.file?.name)
              ?.sort((a, b) => a.order - b.order)
              .map((type) => {
                const src =
                  type.file !== null
                    ? `${S3_ENDPOINT}/${type.file.name}.${type.file.type}`
                    : "";

                return (
                  <div className="online-embla__slide" key={type.class_type_id}>
                    <div className="online-embla__slide__card position-relative">
                      <img
                        alt="class type image"
                        src={src}
                        className="w-100 h-100 position-absolute top-0 bottom-0 end-0 start-0"
                        style={{ objectFit: "cover" }}
                      />
                      <div className="online-embla__type-description gothic-font">
                        <h2 className="type-description__title mb-auto">
                          {type.name}
                        </h2>
                        <h2 className="type-description__videos mb-auto">
                          {type.videos.length} Videos
                        </h2>

                        <button
                          type="button"
                          onClick={() => setCurrentClassType(type)}
                          className="type-description__btn btn bg-transparent p-0 h-max-content"
                        >
                          VER BENEFICIOS
                          <i className="fas fa-chevron-right ms-2" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="online-embla__buttons h-max-content position-absolute w-100 end-0 start-0 mx-auto bottom-0 mt-auto">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="online-embla__controls">
          <div className="online-embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"online-embla__dot".concat(
                  index === selectedIndex ? " online-embla__dot--selected" : ""
                )}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OnlineClassTypesCarousel;
