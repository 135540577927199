import React, { useCallback } from "react";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import "./FiltersCarousel.css";
import { S3_ENDPOINT } from "../../utils";
import { ReactComponent as Video } from "../../assets/icons/video.svg";

export default function FiltersCarousel(props) {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ delay: 2500 }),
  ]);

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;
    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  );

  const renderVideos = (classType) => {
    const { videos } = classType;
    if (videos && videos !== null) {
      return (
        <span className="class-type-videos text-white text-shadow">
          <span className="me-2" style={{
            fontSize: "12px",
          }} >{videos.length} Videos </span>
          <Video width={20} height={20} fill="#fff" className="" />
        </span>
      );
    }
  };

  return (
    <section className="filters-carousel">
      <div className="filters-carousel__viewport" ref={emblaRef}>
        <div className="filters-carousel__container">
          {slides?.map((classType, index) => (
            <div className="filters-carousel__slide">
              <div
                className={`card shadow-sm position-relative mb-3 bg-image class-type-card h-100`}
              >
                <img
                  alt={classType.name}
                  src={`${S3_ENDPOINT}/${classType.file.name}.${classType.file.type}`}
                  className={
                    "position-absolute top-0 bottom-0 start-0 end-0 w-100 h-100"
                  }
                  style={{ objectFit: "cover" }}
                />
                {renderVideos(classType)}
                <div className="class-type-meta" style={{ height: "40px", borderRadius: "5px" }}>
                  <div className="container text-center">
                    <h4 className="mb-0 fs-6">{classType.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="filters-carousel__controls d-flex justify-content-center">
        <div className="filters-carousel__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'filters-carousel__dot'.concat(
                index === selectedIndex ? ' filters-carousel__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div> */}
    </section>
  );
}
