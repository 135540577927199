import React, { useContext, useEffect, useRef } from "react";
import "./LandingOnlineFilters.css";

import FiltersCarousel from "./FiltersCarousel";

import barreImg from "../../assets/online/barre.png";
import cardioImg from "../../assets/online/cardio.png";
import glideImg from "../../assets/online/glide.png";
import jumpImg from "../../assets/online/jump.png";
import killerPackImg from "../../assets/online/killer-pack.png";
import morningImg from "../../assets/online/morning.png";
import { ProgramsContext } from "../../context/ProgramsContext";
import { S3_ENDPOINT } from "../../utils";
import { ClassTypeContext } from "../../context/ClassTypesContext";

export default function LandingOnlineFilters() {
  const containerRef = useRef(null);
  const { getPrograms, programs } = useContext(ProgramsContext);
  const { class_types } = useContext(ClassTypeContext);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    getPrograms();
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        }
      });
    };

    // Creamos el IntersectionObserver
    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    // Observamos los componentes
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  const filtersList = [
    "Busca la clase por su nombre",
    "Filtra por categoría: Fuerza, Cardio, Magic key, etc",
    "Filtra por coach",
    "Filtra por duración: desde menos de 20 min hasta más de 1 hora",
    "Filtra por target muscular",
    "Filtra por impacto",
  ];

  const slides = [
    barreImg,
    cardioImg,
    glideImg,
    jumpImg,
    killerPackImg,
    morningImg,
  ];

  const filteredClassTypes = class_types?.filter((type) => {
    return type.file && type.file?.type && type.file?.name;
  });

  const renderFiltersList = () => {
    return filtersList.map((filter, index) => (
      <div key={index} className="filters__item d-flex align-items-center">
        <i className="fas fa-check-circle me-2"></i>
        <div className="filters__item-text">{filter}</div>
      </div>
    ));
  };

  const renderPrograms = () => {

    if (Array.isArray(programs) && programs.length > 0) {
      const filteredPrograms = programs?.filter((program) => {
        return program.name !== "Holistic";
      });

      const sortedPrograms = [
        { name: "Estrenos", color: "rgb(237,184,128)", icon: "iconosTBM-01.png" },
        filteredPrograms[2],
        filteredPrograms[1],
        filteredPrograms[0],
      ]
      
      return sortedPrograms.map((program, index) => (
        <div
          className=" col-6 col-sm-3 col-lg-6 position-relative custom-ratio"
          style={{ height: "calc(=" }}
        >
          <div
            style={{ backgroundColor: program.color }}
            className="h-100 d-flex flex-column justify-content-center align-items-center"
          >
            <div className="programa-overlay bg-black "></div>
            <img
              src={`${S3_ENDPOINT}/${program.icon}`}
              alt="Imagen 1"
              className=" rounded-none"
              style={{ width: "40%" }}
            />
            <h6 className="text-white m-0">{program.name}</h6>
          </div>
        </div>
      ));
    }
  };
  return (
    <div className="online-filters bg-white ">
      <div ref={containerRef} className="opacity-0">
        <div
          className="d-flex justify-content-between mx-auto filters__container"
          style={{ maxWidth: "1100px" }}
        >
          <div className="filters__list">
            <h6 className="online-subtitle text-dark-secondary mb-1">
              Busca tus clases
            </h6>
            <h2 className="online-title text-dark-secondary">
              Usando los distintos filtros
            </h2>
            {renderFiltersList()}
          </div>

          <div className="filters__programas">
            <h6 className="online-subtitle text-dark-secondary mb-3">
              Filtra por programa
            </h6>
            <div className="row g-4">
              {" "}
              {/* g-4 agrega espacio entre columnas y filas */}
              {renderPrograms()}
            </div>
          </div>
        </div>

        <div className="online-filters__carrousel d-flex flex-column align-items-center">
          <h6 className="online-subtitle text-dark-secondary w-100 text-md-center ">
            Filtra por tipo de clase
          </h6>
          <FiltersCarousel
            options={{ loop: true, duration: 30 }}
            slides={filteredClassTypes}
          />
          <button type="button" className="online-transparent-btn mt-md-5 mt-3">
            PRUÉBALO 7 DÍAS GRATIS
          </button>
        </div>
      </div>
    </div>
  );
}
