import React from "react";
import LandingOnlineNavbar from "../components/landing-online/LandingOnlineNavbar";
import LandingOnlineHero from "../components/landing-online/LandingOnlineHero";
import LandingOnlineClassTypes from "../components/landing-online/LandingOnlineClassTypes";
import "../components/landing-online/LandingOnline.css";
import LandingOnlineBanner from "../components/landing-online/LandingOnlineBanner";
import LandingOnlineMetodo from "../components/landing-online/LandingOnlineMetodo";
import LandingOnlineFeatures from "../components/landing-online/LandingOnlineFeatures";
import LandingOnlineWorkoutPlanner from "../components/landing-online/LandingOnlineWorkoutPlanner";
import LandingOnlineFilters from "../components/landing-online/LandingOnlineFilters";
import LandingOnlineFavorites from "../components/landing-online/LandingOnlineFavorites";
import LandingOnlineTestimonials from "../components/landing-online/LandingOnlineTestimonials";
import LandingOnlineFooter from "../components/landing-online/LandingOnlineFooter";
import LandingOnlineMobileNavbar from "../components/landing-online/LandingOnlineMobileNavbar";
const LandingOnline = () => {
  return (
    <div id="landing__online">
      <LandingOnlineNavbar />
      <LandingOnlineMobileNavbar />
      <LandingOnlineHero />
      <LandingOnlineClassTypes />
      <LandingOnlineBanner/>
      <LandingOnlineMetodo />
      <LandingOnlineFeatures />
      <LandingOnlineWorkoutPlanner />
      <LandingOnlineFilters />
      <LandingOnlineFavorites/>
      <LandingOnlineTestimonials />
      <LandingOnlineFooter/>
    </div>
  );
};

export default LandingOnline;
