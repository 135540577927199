import React, { useEffect, useRef } from "react";
import "./LandingOnlineFeatures.css";
import FeaturesCarousel from "./FeaturesCarousel";

import image1 from "../../assets/online/TBM-1.jpg";
import image2 from "../../assets/online/TBM-2.jpg";
import image3 from "../../assets/online/TBM-4.jpg";

export default function LandingOnlineFeatures() {
  const containerRef = useRef(null);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        } 
      });
    };


    // Creamos el IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    // Observamos los componentes
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  const features = [
    {
      title: "Clases en línea y presenciales",
      description:
        "Déjate guiar por nuestras Master Coaches. Encuentra más de 600 clases + estrenos semanales desde cualquier lugar.",
    },
    {
      title: "Workout Planner",
      description:
        "Organiza tus clases según tus objetivos, días de entrenamiento y experiencia fitness.",
    },
    {
      title: "Lleva registro de tu progreso",
      description:
        "Programa tus metas físicas y mentales. Mide tu progreso y registra los pequeños pasos diarios.",
    },
    {
      title: "Comunidad",
      description:
        "Comparte tus experiencias y motívate en una comunidad de mujeres con la misma pasión por ser mejores.",
    },
  ];

  const renderFeatures = () => {
    return features.map((feature, index) => (
      <div
        key={index}
        className={`features__content__feature d-flex ${
          index !== features.length - 1 ? "content__feature-border" : ""
        }`}
      >
        <i className="fas mt-1 fa-check-circle me-2" />
        <span className={`feature__desc `}>
          <b className="d-block">{feature.title}</b>
          {feature.description}
        </span>
      </div>
    ));
  };

  const imagesList = [
    image1,
    image2,
    image3,
  ]

  return (
    <div className="online__features opacity-0 mx-auto" style={{ maxWidth: "1100px"}} ref={containerRef}>
      <div className="features__content">
        <h6 className="online-subtitle text-dark-secondary mb-0">
          Tu bienestar, a un clic de distancia
        </h6>
        <h2 className="online-title text-dark-secondary">
          TODO LO QUE NECESITAS EN UN MISMO LUGAR
        </h2>
        {renderFeatures()}

        <button type="button" className="features__content--btn">
          CONOCE TU PLAN IDEAL
        </button>
      </div>

      <FeaturesCarousel options={{ loop: true }} slides={imagesList}/>
    </div>
  );
}
