import React from "react";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "../global/EmblaCarouselArrowButtons";
import { DotButton, useDotButton } from "../global/EmblaCarouselDotButton";

export default function BrandFeaturesTable({
  currentBrand,
  emblaApi,
  setShowCarousel,
}) {
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const renderStars = (amount) => {
    let current = 0;
    const stars = [];

    do {
      stars.push(
        <div
          key={`${current}-${amount}`}
          className="item__star p-0"
          style={{
            width: 30,
            height: 30,
          }}
        />
      );

      current++;
    } while (current < amount);

    return stars;
  };

  const accordionId = `accordion-${currentBrand.instagram.replace(
    /[^a-zA-Z0-9_-]/g,
    ""
  )}`;

  return (
    <div className="brands-features__slide">
      <div className="bg-white h-100 slide__container">
        <div className="row w-100 justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowCarousel(false)}
          ></button>
        </div>

        <div className="row flex-column align-items-center">
          <h2 className="title font-dm-sans">BENEFICIOS</h2>
          {currentBrand?.logo ? (
            <img
              alt="reward brand"
              src={currentBrand?.logo}
              className="w-auto mb-2"
            />
          ) : (
            <div style={{ height: "83px" }}></div>
          )}

          <span className="instagram font-dm-sans w-max-content">
            <i className="fab fa-instagram me-2" />
            {currentBrand?.instagram}
          </span>
        </div>

        <div className="row w-100 mx-auto hide-mobile">
          <div style={{ height: "62px" }} className="row w-100 mx-auto p-0">
            <div
              className="col-4 px-0 purple-border-right"
              style={{ width: "256px" }}
            ></div>
            <div className="col-8 px-0" style={{ width: "660px" }}>
              <div className="row w-100 mx-auto p-0 h-100">
                <div className="col text-center d-flex align-items-center justify-content-center">
                  <p className="feat font-dm-sans my-auto w-max-content">
                    BENEFICIOS
                  </p>
                </div>
                <div className="col text-center d-flex align-items-center justify-content-center">
                  <p className="feat font-dm-sans my-auto w-max-content">
                    APLICACIÓN
                  </p>
                </div>
                <div className="col text-center d-flex align-items-center justify-content-center">
                  <p className="feat font-dm-sans my-auto w-max-content">
                    RESTRICCIONES
                  </p>
                </div>
                <div className="col text-center d-flex align-items-center justify-content-center">
                  <p className="feat font-dm-sans my-auto w-max-content">
                    VIGENCIA
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" px-0 py-2 purple-border-right gray-border-top"
            style={{ width: "256px" }}
          >
            {currentBrand?.levels?.map((level, index) => (
              <div
                key={level.number}
                className={`row w-100 align-items-center ps-2 mx-auto `}
                style={{
                  height: "56px",
                  backgroundColor: [0, 2, 4].includes(index) ? "#F2F2F2" : "",
                }}
              >
                <span className="dm-sans-font me-2 p-0 w-max-content level">
                  NIVEL {level.number}
                </span>
                {renderStars(level.number)}
              </div>
            ))}
          </div>
          <div
            className="col-8 px-0 py-2 gray-border-top"
            style={{ width: "660px" }}
          >
            {currentBrand?.levels?.map((level, index) => (
              <div
                key={level.number}
                className={`row w-100 align-items-center ps-2 mx-auto `}
                style={{
                  height: "56px",
                  backgroundColor: [0, 2, 4].includes(index) ? "#F2F2F2" : "",
                }}
              >
                <div className="col text-center feat-content font-dm-sans">
                  {level?.benefit ? level.benefit : "-"}
                </div>
                <div className="col text-center feat-content font-dm-sans">
                  {level?.application ? level.application : "-"}
                </div>
                <div className="col text-center feat-content font-dm-sans">
                  {level?.restrictions ? level.restrictions : "-"}
                </div>
                <div className="col text-center feat-content font-dm-sans">
                  {level?.expiration ? level.expiration : "-"}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="row w-100 mx-auto show-mobile">
          <div id={accordionId} className="accordion accordion-brands bg-white">
            {currentBrand.levels.map((level, index) => {
              const levelId = `collapseLevel-${currentBrand.instagram.replace(
                /[^a-zA-Z0-9_-]/g,
                ""
              )}-${level.number}`;
              return (
                <div
                  className="accordion-item border rounded mb-2 "
                  key={level.number}
                >
                  <h2 className="accordion-header bg-transparent">
                    <button
                      className="accordion-button bg-transparent collapsed py-2 px-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${levelId}`}
                      aria-expanded="false"
                      aria-controls={levelId}
                    >
                      <h2 className="bold item__title me-2">
                        NIVEL {level.number}
                      </h2>
                      {renderStars(level.number)}
                    </button>
                  </h2>
                  <div
                    id={levelId}
                    className="accordion-collapse collapse"
                    data-bs-parent={`#${accordionId}`}
                  >
                    <div className="accordion-body small px-3 py-2">
                      <p className="mb-1 " style={{ fontSize: "12px" }}>
                        <strong style={{ fontSize: "15px" }}>Beneficio:</strong>{" "}
                        {level.benefit}
                      </p>
                      <p className="mb-1 " style={{ fontSize: "12px" }}>
                        <strong style={{ fontSize: "15px" }}>
                          Aplicación:
                        </strong>{" "}
                        {level.application}
                      </p>
                      <p className="mb-1 " style={{ fontSize: "12px" }}>
                        <strong style={{ fontSize: "15px" }}>
                          Restricciones:
                        </strong>{" "}
                        {level.restrictions}
                      </p>
                      <p className="mb-0 " style={{ fontSize: "12px" }}>
                        <strong style={{ fontSize: "15px" }}>Vigencia:</strong>{" "}
                        {level.expiration}
                      </p>

                      {Array.isArray(level.features) &&
                        level?.features?.length > 0 && (
                          <div className="d-flex ">
                            {
                              level.features.map((feature, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="d-flex me-3 flex-column align-items-center justify-content-center w-max-content item__feature"
                                  >
                                    <div
                                      className="bg-white d-flex justify-content-center align-items-center"
                                      style={{
                                        borderRadius: "50%",
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    >
                                      {feature.icon(true)}
                                    </div>
                                    <span className="text-white">{feature.text}</span>
                                  </div>
                                );
                              })
                            }
                          </div>
                        )
                        }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="brands-features__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="brands-features__controls">
          <div className="brands-features__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"brands-features__dot".concat(
                  index === selectedIndex
                    ? " brands-features__dot--selected"
                    : ""
                )}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
