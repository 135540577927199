import React, { useEffect, useRef, useState } from "react";
import "./LandingOnlineFooter.css";

export default function LandingOnlineFooter() {
  const [gradientPosition, setGradientPosition] = useState(43);

  const containerRef = useRef(null);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        }
      });
    };

    // Creamos el IntersectionObserver
    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    // Observamos los componentes
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const percentage = 100 - (e.clientY / window.innerHeight) * 100;
      setGradientPosition(percentage);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="online-footer overflow-hidden d-flex flex-column align-items-center opacity-0" ref={containerRef}>
      <div
        style={{
          backgroundImage: `radial-gradient(at top right,#92b5d5 ${gradientPosition}%,#a22749 88%)`,
        }}
        className="online-footer__background"
      ></div>

      <h6 className="online-subtitle text-center mb-1">
        Diseñado para Cualquier Nivel
      </h6>
      <h2 className="online-title text-center mb-5">TBM es para Todos</h2>

      <p className="text-center footer__desc">
        <strong>
          No importa si eres principiante o avanzada, The Body Method se adapta
          a ti.
        </strong>
      </p>
      <p className="text-center footer__desc">
        Nuestro enfoque inclusivo y personalizado te permite disfrutar de los
        beneficios del fitness, sin importar tu nivel de experiencia.{" "}
        <strong>
          Con clases diseñadas para todos los niveles, nuestras coaches te
          guiarán para que alcances tus metas de manera segura y efectiva.
        </strong>
      </p>

      <button type="button" className="btn footer__btn mt-4">PRUÉBALO 7 DÍAS GRATIS</button>

      <div className="footer__brand">
        2024
        <sup>©</sup>{""}
        | THE BODY METHOD
      </div>
    </div>
  );
}
