import React from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";

const SingleEstreno = ({ video }) => {
  const renderFile = () => {
    const { file } = video;
    if (file && file !== null) {
      return (
        <img
          alt={video.name}
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="estreno-image mw-100 w-100 br-25 m-auto d-block"
        />
      );
    }
    return <div style={{ height: 280 }} className="bg-loading br-25 w-100 mw-100 mb-3" />;
  };

  const renderInstructor = () => {
    if (video.instructor && video.instructor !== null) {
      return (
        <p className="mb-2 mb-md-3">
          <i className="fa fa-user-circle me-1" /> {video.instructor.name}{" "}
          {video.instructor.last_name}
        </p>
      );
    }
  };
  return (
    <Link
      to={`/mytbm/video/${video.video_id}`}
      id="single-estreno"
      className="row p-xl-5 p-md-4 p-2 align-items-center no-decoration"
    >
      <div className="col-12 col-md-6 pb-md-4 pb-1 px-4 h-100">
        <h2 className="h1 mb-2 mb-md-4 text-white show-mobile">Estreno</h2>
        {renderFile()}
      </div>
      <div className="col-12 col-md-6 pb-4 px-4 text-white">
        <h2 className="h1 hide-mobile">Estreno</h2>
        <h3 className="h4 mb-2 mb-md-2">{video.name}</h3>
        {renderInstructor()}
        <p className="small mb-2 mb-md-4">{video.description}</p>
        <div
          to={`/mytbm/video/${video.video_id}`}
          className="btn w-100 btn-warning bold text-uppercase"
        >
          Ver Video
        </div>
      </div>
    </Link>
  );
};

export default SingleEstreno;
