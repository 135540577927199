import React, { useContext, useEffect } from "react";
import "./LandingOnlineClassTypes.css";
import OnlineClassTypesCarousel from "./OnlineClassTypesCarousel";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { S3_ENDPOINT } from "../../utils";
import BenefitChevronRight from "./BenefitChevronRight";
export default function LandingOnlineClassTypes() {
  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const [currentClassType, setCurrentClassType] = React.useState(null);

  useEffect(() => {
    getClassTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSingleClassType = () => {
    if (currentClassType) {
      const src =
        currentClassType.file !== null
          ? `${S3_ENDPOINT}/${currentClassType.file.name}.${currentClassType.file.type}`
          : "";
      const benefits = currentClassType.benefits.split("/");

      return (
        <div className="bg-white online__class-types--modal">
          <img alt="class type image" src={src} style={{ objectFit: "cover"}}/>
          <div className="class-types--modal__content">
            <i
              onClick={() => {
                setCurrentClassType(null);
              }}
              className="fas fa-times position-absolute cursor-pointer text-dark-secondary end-0 top-0 me-4 mt-4"
            />
            <h2>{currentClassType.name}</h2>

            <p className="modal__content--benefits mb-0">
              <strong className="">Beneficios: </strong>
            </p>

            {benefits.map((benefit, index) => (
              <span
                className={`w-100 d-flex align-items-start ${
                  index === benefits.length - 1
                    ? ""
                    : "modal__content--benefit-border"
                }`}
                key={index}
              >
                {/* <i className="fas fa-chevron-right me-2 bold" style={{ fontSize: "10px"}}/> */}
                <BenefitChevronRight className="me-2 " />
                {benefit}
              </span>
            ))}

            <button className="btn online-transparent-btn" type="button">
              PRUÉBALO 7 DÍAS GRATIS
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      id="onlineClassTypes"
      className="container-fluid online__class-types position-relative animate-fade-in "
    >
      {renderSingleClassType()}
      {currentClassType && <div className="class-types--modal__backdrop"></div>}
      <h2 className="class-types__title">
        EXPLORA 9 DISCIPLINAS ÚNICAS EN NUESTRA PLATAFORMA ONLINE
      </h2>
      <p className="class-types__desc">
        {" "}
        Accede a más de 500 clases disponibles con nuevos estrenos cada semana.
      </p>

      <OnlineClassTypesCarousel
        setCurrentClassType={setCurrentClassType}
        class_types={class_types?.filter(({ file }) => file !== null)}
      />
    </div>
  );
}
