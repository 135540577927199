import React, { useEffect } from "react";
import { Router, useLocation } from "@reach/router";
import MainHome from "./views/MainHome";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import Recuperar from "./views/Recuperar";
import Panel from "./views/Panel";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import Gracias from "./views/Gracias";
import Loading from "./views/Loading";
import Checkout from "./views/Checkout";
import Paquetes from "./views/Paquetes";
import Terms from "./components/global/Terms";
import PreguntasFrecuentes from "./components/global/PreguntasFrecuentes";
import Ubicacion from "./components/global/Location";
import Reto from "./views/pages/Reto";
import Certificaciones from "./views/pages/Certificaciones";
import CoachPage from "./views/pages/CoachPage";
import ProgramPage from "./views/pages/ProgramPage";
import WhatsApp from "./components/global/WhatsApp";
import WorkoutPlanner from "./views/pages/WorkoutPlanner";
import ClassTypePage from "./views/pages/ClassTypePage";
// import SinglePage from "./views/pages/SinglePage";
import useSocket from "./hooks/useSocket";
import PlannerResult from "./components/planner/PlannerResult";
import { setupLocale } from "./utils/dates";
import useBranch from "./hooks/useBranch";
import HomePresencial from "./views/HomePresencial";
// import LandingOnline from "./views/LandingOnline";
import HomeOnline from "./views/HomeOnline";
import { AppConfigContext } from "./context/AppConfigContext";
import HomeMonterrey from "./views/HomeMonterrey";
import LandingRewards from "./views/LandingRewards";

const Main = () => {
  const [socket, setupSocket] = useSocket();
  const { branch, getBranches } = useBranch();
  const { user, userLoggedIn } = useContext(AuthContext);
  const { getAppConfig } = useContext(AppConfigContext);

  const location = useLocation();

  useEffect(() => {
    setupSocket();
    setupLocale();
    userLoggedIn();
    getBranches();
    getAppConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!location.pathname.endsWith("/")) {
      window.history.replaceState({}, "", location.pathname + "/");
    }
  }, [location]);

  const renderContent = () => {
    if (branch !== null) {
      return (
        <Router>
          <MainHome path="/*" />
          <LandingRewards path="/rewards/*"/>
          <HomeOnline path="/online/*" />
          <HomeMonterrey path="/monterrey/*" />
          <HomePresencial path="/tijuana/*" />
          <Terms path="/terminos" />
          <PreguntasFrecuentes path="/preguntas-frecuentes" />
          <Ubicacion path="/ubicacion" />
          <Reto path="/reto" />
          <WorkoutPlanner path="/pages/workout-planner" />
          <PlannerResult path="/pages/workout-planner-result/:workout_plan_id" />
          <Certificaciones path="/certificaciones" />
          <CoachPage path="/coach/:coach_id" />
          <ProgramPage path="/program/:program_id" />
          <ClassTypePage path="/class_type/:class_type_id" />
          <Login path="/entrar" />
          <SignUp path="/registro" />
          <Recuperar path="/recuperar" />
          <Checkout path="/checkout/:class_package_id" />
          <Gracias path="/gracias/:purchase_id" />
          <Paquetes path="/paquetes/" />
          {/*<SinglePage path="/pages/:slug" />*/}
          {user !== null ? (
            <Panel path="mytbm/*" />
          ) : (
            <Loading path="mytbm/*" />
          )}
        </Router>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      {renderContent()}
      <WhatsApp />
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
