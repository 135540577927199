import React, { useEffect, useRef } from "react";
import "./LandingOnlineTestimonials.css";

import user1 from "../../assets/online/testimonials-1.jpg";
import user3 from "../../assets/online/testimonials-2.jpg";
import user2 from "../../assets/online/testimonials-3.jpg";
import OnlineTestimonialsCarousel from "./OnlineTestimonialsCarousel";

export default function LandingOnlineTestimonials() {

  const cardsList = [
    {
      userImg: user1,
      userName: "Cecilia Vargas",
      userCity: "Ciudad de México",
      cardHeight: "440px",
      testimonial:
        `"A los ojos de los demás puede no tener un cambio importante, pero a mí me ha hecho sentir mejor, me ayudó a retomar la rutina, sentirme más fuerte y sobre todo, sentirme mejor conmigo misma."`,
    },
    {
      userImg: user2,
      userName: "Katya Cardenas",
      userCity: "Monterrey",
      cardHeight: "510px",
      testimonial:
        `"Empecé en The Body Method cuando vivía en Monterrey, justo antes de la pandemia. Ahora, viviendo en Washington, sigo las clases en línea con mucha disciplina. Hago 5 clases por semana y me siento fuerte, feliz y llena de energía. ¡Incluso mi novio se une a veces! Retomé TBM por mi salud y porque me caso en octubre. Llevo casi tres meses con TBM Online y los resultados son increíbles."`,
    },
    {
      userImg: user3,
      userName: "Karen Rodriguez",
      userCity: "Guadalajara",
      cardHeight: "440px",
      testimonial:
        `"Conocí The Body Method desde su inauguración. Después de mi clase de prueba, me inscribí de inmediato. He probado muchos ejercicios, pero ninguno me ha funcionado como TBM. En ningún lugar he logrado tonificarme tan rápido y divertirme tanto. Soy la más feliz de haber conocido este método mágico guiada por las mejores coaches."`,
    }
  ]

  const containerRef = useRef(null);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        }
      });
    };

    // Creamos el IntersectionObserver
    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    // Observamos los componentes
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);
  return (
    <div id="scrollspyTestimonios" className="online-testimonials bg-white d-flex flex-column align-items-center" >
      <div className="opacity-0 d-flex flex-column align-items-center w-100 h-100" ref={containerRef}>

      <h6 className="online-subtitle text-dark-secondary text-center mb-1">
        Nuestra comunidad
      </h6>
      <h2 className="online-title text-dark-secondary text-center">
        Testimonios
      </h2>

      {/* <div style={{ display: "flex"}} className="testimonials__cards hide-mobile justify-content-between align-items-start">
        <div className="single__card cards__first-card">
          <div className="card__profile d-flex align-items-center justify-content-start">
            <img src={user1} alt="user image" />
            <div className="d-flex flex-column">
              <strong className="profile__name">Cecilia Vargas</strong>
              <span className="profile__city">Ciudad de México</span>
            </div>
          </div>

          <p className="card__content">
            "A los ojos de los demás puede no tener un cambio importante, pero a
            mí me ha hecho sentir mejor, me ayudó a retomar la rutina, sentirme
            más fuerte y sobre todo, sentirme mejor conmigo misma."
          </p>
        </div>

        <div className="single__card cards__second-card">
          <div className="card__profile d-flex align-items-center justify-content-start">
            <img src={user2} alt="user image" />
            <div className="d-flex flex-column">
              <strong className="profile__name">Katya Cardenas</strong>
              <span className="profile__city">Monterrey</span>
            </div>
          </div>

          <p className="card__content">
            "Empecé en The Body Method cuando vivía en Monterrey, justo antes de
            la pandemia. Ahora, viviendo en Washington, sigo las clases en línea
            con mucha disciplina. Hago 5 clases por semana y me siento fuerte,
            feliz y llena de energía. ¡Incluso mi novio se une a veces! Retomé
            TBM por mi salud y porque me caso en octubre. Llevo casi tres meses
            con TBM Online y los resultados son increíbles."
          </p>
        </div>

        <div className="single__card cards__third-card">
          <div className="card__profile d-flex align-items-center justify-content-start">
            <img src={user3} alt="user image" />
            <div className="d-flex flex-column">
              <strong className="profile__name">Karen Rodriguez</strong>
              <span className="profile__city">Guadalajara</span>
            </div>
          </div>

          <p className="card__content">
            "Conocí The Body Method desde su inauguración. Después de mi clase
            de prueba, me inscribí de inmediato. He probado muchos ejercicios,
            pero ninguno me ha funcionado como TBM. En ningún lugar he logrado
            tonificarme tan rápido y divertirme tanto. Soy la más feliz de haber
            conocido este método mágico guiada por las mejores coaches."
          </p>
        </div>
      </div> */}

      <OnlineTestimonialsCarousel slides={cardsList} options={{
        loop: true,
        autoplay: false,
        align: "start"
      }}/>

      <button type="button" className="btn online-transparent-btn testimonials__btn">PRUÉBALO 7 DÍAS GRATIS</button>
      </div>

    </div>
  );
}
