import React, { useContext, useEffect } from "react";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import { Link } from "@reach/router";
import moment from "moment";

const MisCreditos = () => {
  const { available_classes, getAvailableClassesByDate } = useContext(
    AvailableClassesContext
  );

  useEffect(() => {
    getAvailableClassesByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAvailableClasses = () => {
    if (Array.isArray(available_classes)) {
      return available_classes.map((credits, index) => (
        <tr key={index}>
          <td>{moment(credits.expiration_date).utc().format("DD MMM YYYY")}</td>
          <td>{credits.available_classes}</td>
        </tr>
      ));
    }
  };

  return (
    <div className="container-fluid px-xl-5 px-4 py-3">
      <div className="row mb-3">
        <div className="col-6">
          <h2>Mis Créditos</h2>
        </div>
        <div className="col-6 text-end">
          <Link to="/mystudio/paquetes" className="btn btn-primary">
            + Créditos
          </Link>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table ">
          <thead className="bg-light bold border">
            <tr>
              <td>Vencimiento</td>
              <td>Créditos</td>
            </tr>
          </thead>
          <tbody>{renderAvailableClasses()}</tbody>
        </table>
      </div>
    </div>
  );
};
export default MisCreditos;
