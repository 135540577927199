import React, { useContext, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { S3_ENDPOINT } from "../../utils";
import html2canvas from "html2canvas";
import "./milestones.css";

const MilestoneAchieved = ({ milestone }) => {
  const ref = useRef(null);
  const { user } = useContext(AuthContext);

  const backgroundImage = `url(${S3_ENDPOINT}/customer-milestone-${milestone.milestone_id}.jpg)`;

  const handleShare = () => {
    html2canvas(ref.current, {
      allowTaint: true,
      useCORS: true,
    }).then(async function (canvas) {
      canvas.style.backgroundImage = backgroundImage;
      var base64Data = canvas.toDataURL();
      const base64Response = await fetch(base64Data);
      const blob = await base64Response.blob();
      const filesArray = [
        new File([blob], `milestone.jpg`, {
          type: "image/jpg",
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        files: filesArray,
      };
      if (navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData);
      }
    });
  };

  return (
    <div
      className="text-center milestone-container"
      ref={ref}
      style={{
        backgroundImage,
      }}
    >
      <h1 className="h4 text-white congrats w-100 text-center">
        ¡Felicidades!
        <br />
        Has alcanzado el
      </h1>
      <h2 className="h1 milestone-user-name text-center w-100 text-white">
        {user.name}
      </h2>
      <div className="px-3 w-100 btn-share show-mobile text-center">
        <button onClick={handleShare} className="btn m-auto btn-primary">
          <i className="fab fa-instagram" /> Share
        </button>
      </div>
    </div>
  );
};

export default MilestoneAchieved;
