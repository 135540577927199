import React from 'react'
import "./RewardsHero.css";

export default function RewardsHero() {
  return (
    <div className='container-fluid px-0 rewards-hero'>
      <h3 className='hero__title'>WELCOME TO THE TBM REWARDS</h3>
      <h2 className='hero__tbm'>THE BODY METHOD</h2>
      <img alt='TBM team' src='/img/rewards-hero-edit.png' className=''/>
    </div>
  )
}
