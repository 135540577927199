import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import Waitlist from "./Waitlist";
import AuthForm from "../auth/AuthForm";
import CancelClass from "./CancelClass";
import { hideModal } from "../../utils";
import { Link, navigate } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import SingleClassMonterrey from "./SingleClassMonterrey";
import { ReservationsContext } from "../../context/ReservationsContext";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";

const MonterreyScheduleClass = ({ singleClass, isHome }) => {
  const [needsLogin, setNeedsLogin] = useState(false);
  const { user, getUsuario } = useContext(AuthContext);
  const { available_classes } = useContext(AvailableClassesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { reservations, postReservacion, cancelReservacion } =
    useContext(ReservationsContext);

  useEffect(() => {
    if (user !== null && needsLogin) {
      clearModal();
      setTimeout(() => {
        setNeedsLogin(false);
        reservarClase();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const showClassModal = () => {
    if (isHome) {
      return navigate("/entrar");
    }
    if (isReserved()) {
      modalComponent(
        "Cancelar clase",
        <CancelClass
          singleClass={singleClass}
          reservations={reservations}
          cancelReservacion={(class_reservation_id) =>
            cancelReservacion(class_reservation_id, getUsuario)
          }
        />
      );
    }
  };

  const isFull = () => {
    if (singleClass !== null) {
      if (singleClass.capacity !== null && singleClass.class_reservations) {
        return singleClass.capacity - singleClass.reservations <= 0;
      }
    }
  };

  const isReserved = () => {
    if (reservations && reservations !== null) {
      let class_ids = reservations
        .filter((reservation) => reservation.deletedAt === null)
        .map(({ single_class_id }) => single_class_id);
      return class_ids.includes(singleClass.single_class_id);
    }
    return false;
  };

  const reservarClase = () => {
    if (isHome) {
      return navigate(`/checkout/152`);
    }
    if (singleClass.class_package_id !== null) {
      return navigate(`/checkout/${singleClass.class_package_id}`);
    }
    if (isFull()) {
      return modalComponent(
        "Lista de Espera",
        <Waitlist
          handleCancel={clearModal}
          single_class_id={singleClass.single_class_id}
        />
      );
    }
    if (user === null) {
      setNeedsLogin(true);
      return modalComponent("Debes Acceder para Reservar", <AuthForm />, {
        no_padding: true,
      });
    }
    if (available_classes?.length > 0) {
      modalComponent(
        "Reservar Clase",
        <SingleClassMonterrey
          clase={singleClass}
          hideModal={hideModal}
          postReservacion={(clase) => postReservacion(clase, getUsuario)}
        />,
        {
          size: "lg",
        }
      );
    } else {
      modalComponent(
        "Créditos Agotados",
        <div className="container-fluid px-0">
          <p>
            Lo sentimos. Has agotado todos tus créditos para reservar Clases
            Presenciales. Puedes comprar más en Paquetes.
          </p>
          <p className="bold">
            Después de comprar, debes regresar a reservar tu clase.
          </p>
          <Link to="/mytbm/paquetes" className="btn w-100 btn-primary">
            Ir a Paquetes
          </Link>
        </div>
      );
    }
  };

  const renderBadges = () => {
    if (isFull()) {
      return (
        <div className="d-block">
          <span className="badge badge-pill text-white bg-dark fw-normal">
            SOLD OUT
          </span>
        </div>
      );
    }
    let spots = singleClass.capacity - singleClass.reservations;

    if (spots <= 5) {
      return (
        <span className=" d-block text-dark fw-normal schedule-class__coach-name">
          {spots} Lugar{spots === 1 ? "" : "es"}
        </span>
      );
    }
  };

  const renderButtons = () => {
    if (isReserved()) {
      return (
        <div className="row mt-2">
          <div className="container-fluid text-center">
            <button
              className="btn btn-outline-light text-white btn-sm"
              onClick={showClassModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      );
    }
    if (singleClass.class_package_id !== null) {
      return (
        <button
          className="btn btn-dark btn-sm mt-2"
          onClick={() => navigate(`/checkout/${singleClass.class_package_id}`)}
        >
          Comprar
        </button>
      );
    }
    const diff =
      moment(singleClass.class_date)
        .utc()
        .local()
        .diff(moment().utc().local(), "hours") + 6;
    if (diff > 0) {
      return (
        <button
          className={`btn btn-sm mt-2 d-block m-auto ${"bg-dark-gray text-white"}`}
          onClick={reservarClase}
        >
          {isFull() ? "Lista de Espera" : "Reservar"}
        </button>
      );
    }
  };

  // const renderInstructors = () => {
  //   return singleClass.class_instructors
  //     .filter(({ instructor }) => instructor !== null)
  //     .map((class_instructor) => (
  //       <span
  //         className={`badge badge-pill me-1 bg-${
  //           isFull() ? "primary-light" : "primary"
  //         }`}
  //         key={class_instructor.instructor_id}
  //       >
  //         {class_instructor.instructor.name}
  //       </span>
  //     ));
  // };

  // const renderClassType = () => {
  //   if (singleClass.class_type !== null) {
  //     return singleClass.class_type.name;
  //   }
  // };

  // const renderDescription = () => {
  //   if (
  //     singleClass.description &&
  //     singleClass.description !== "" &&
  //     singleClass.description !== null
  //   ) {
  //     return <p className="mb-1 small">{singleClass.description}</p>;
  //   }
  // };

  const classTypesBackgrounds = {
    "G&T": "#69655D",
    BARRE: "#97B1D7",
    SNB: "#A23055",
    BBP: "#ECC1C2",
    JUMP: "#BED2D2",
    CARDIO: "#d56a5e",
    MIX: "rgb(149,137,247)",
  };

  const getCoachName = () => {
    if (
      Array.isArray(singleClass?.class_instructors) &&
      singleClass.class_instructors.length > 0
    ) {
      return singleClass.class_instructors[0].instructor?.name;
    }
  };

  const currentClassType = singleClass?.class_type;
  const background = classTypesBackgrounds[singleClass?.class_type?.short_code];
  return (
    <div
      className={`monterrey__schedule-class border d-flex flex-column align-items-center justify-content-center pt-2 ${
        isReserved()
          ? "bg-primary-light"
          : isFull()
          ? "bg-primary text-white"
          : ""
      }`}
    >
      <div className="container-fluid">
        <p className="mb-2 schedule-class__coach-name">{getCoachName()}</p>
        <span
          className="badge rounded-pill mb-2"
          style={{ backgroundColor: background, width: "70px" }}
        >
          {currentClassType?.short_code}
        </span>
        {renderBadges()}
        {renderButtons()}
      </div>
    </div>
  );
};

export default MonterreyScheduleClass;
