import React, { useContext } from "react";
import { Link, navigate } from "@reach/router";
import InlineStreaks from "../streaks/InlineStreaks";
import PlannerProgressBar from "./PlannerProgressBar";
import { WorkoutPlansContext } from "../../context/WorkoutPlansContext";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";

const PlannerResultHeader = ({ customer_workout_plan }) => {
  const { setWorkoutPlan } = useContext(WorkoutPlansContext);
  const { setCustomerWorkoutPlan } = useContext(CustomerWorkoutPlansContext);

  const handleStartOver = () => {
    navigate("/pages/workout-planner");
    setCustomerWorkoutPlan(null);
    setWorkoutPlan(null);
  };

  return (
    <div className="row align-items-center border-bottom mb-3 pb-3 pb-md-0 mt-md-2">
      <div className="col-12 col-md-8 px-0">
        <h1 className="mb-2">
          {customer_workout_plan !== null
            ? "Mi Workout Planner"
            : "Workout Planner"}
          <InlineStreaks />
        </h1>
      </div>
      <div className="col-12 px-0 mobile-left col-md-4 text-end">
        <Link to="/mytbm/online" className="btn btn-outline-primary me-2 mb-2">
          Regresar a TBM Online
        </Link>
        <button
          onClick={handleStartOver}
          className="btn btn-outline-secondary mb-2"
        >
          <i className="fa fa-redo me-1" /> Empezar de Nuevo
        </button>
      </div>
      {customer_workout_plan && customer_workout_plan !== null && (
        <div className="col-12 mt-5 px-0">
          <PlannerProgressBar customer_workout_plan={customer_workout_plan} />
        </div>
      )}
    </div>
  );
};

export default PlannerResultHeader;
