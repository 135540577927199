import React, { useEffect, useState } from "react";
import "./BrandsFeaturesCarousel.css";
import { useDotButton } from "../global/EmblaCarouselDotButton";

import useEmblaCarousel from "embla-carousel-react";
import BrandFeaturesTable from "./BrandFeaturesTable";

const BrandIcon = ({ src }) => {
  return (
    <img
      alt="menu icon"
      width={50}
      height={"auto"}
      src={src}
    />
  );
};

const brandList = [
  {
    logo: "/img/brand-1.png",
    levels: [
      {
        number: 1,
        benefit: "10% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[0].logo} />, text: "10% OFF" },
        ],
      },
      {
        number: 2,
        benefit: "10% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[0].logo} />, text: "10% OFF" },
        ],
      },
      {
        number: 3,
        benefit: "15% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[0].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 4,
        benefit: "15% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[0].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 5,
        benefit: "20% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[0].logo} />, text: "20% OFF" },
        ],
      },
      {
        number: 6,
        benefit: "25% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[0].logo} />, text: "25% OFF" },
        ],
      },
    ],
    instagram: "@habitsproteins",
  },
  {
    logo: "/img/brand-2.png",
    levels: [
      {
        number: 1,
        benefit: "FREE SHIPPING",
        application: "CÓDIGO DE DESCUENTO EN STIIO WEB",
        restrictions: "1 USO POR USUARIO",
        expiration: "DICIEMBRE 2026",
        features: [
          { icon: (small) => <BrandIcon src={brandList[1].logo} />, text: "FREE SHIPPING" },
        ],
      },
      {
        number: 2,
        benefit: "15% OFF",
        application: "CÓDIGO DE DESCUENTO EN STIIO WEB",
        restrictions: "1 USO POR USUARIO",
        expiration: "DICIEMBRE 2026",
        features: [
          { icon: (small) => <BrandIcon src={brandList[1].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 3,
        benefit: "20% OFF",
        application: "CÓDIGO DE DESCUENTO EN STIIO WEB",
        restrictions: "1 USO POR USUARIO",
        expiration: "DICIEMBRE 2026",
        features: [
          { icon: (small) => <BrandIcon src={brandList[1].logo} />, text: "20% OFF" },
        ],
      },
      {
        number: 4,
        benefit: "25% OFF",
        application: "CÓDIGO DE DESCUENTO EN STIIO WEB",
        restrictions: "1 USO POR USUARIO",
        expiration: "DICIEMBRE 2026",
        features: [
          { icon: (small) => <BrandIcon src={brandList[1].logo} />, text: "25% OFF" },
        ],
      },
      {
        number: 5,
        benefit: "GIFT CARD $250",
        application: "CÓDIGO DE DESCUENTO EN STIIO WEB",
        restrictions: "1 USO POR USUARIO",
        expiration: "DICIEMBRE 2026",
        features: [
          { icon: (small) => <BrandIcon src={brandList[1].logo} />, text: "GIFT CARD" },
        ],
      },
      {
        number: 6,
        benefit: "DERMOLIMPIADOR FACIAL FREE",
        application: "CÓDIGO DE DESCUENTO EN STIIO WEB",
        restrictions: "1 USO POR USUARIO",
        expiration: "DICIEMBRE 2026",
      },
    ],
    instagram: "@elisazambrano",
  },
  {
    logo: "/img/brand-3.png",
    levels: [
      {
        number: 1,
        benefit: "FREE SHIPPING",
        application: "CÓDIGO DE DESCUETNO",
        restrictions: "SE PUEDE USAR 2 VECES AL MES",
        expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        features: [
          { icon: (small) => <BrandIcon src={brandList[2].logo} />, text: "FREE SHIPPING" },
        ],
      },
      {
        number: 2,
        benefit: "10% OFF EN SITIO WEB",
        application: "CÓDIGO DE DESCUETNO",
        restrictions:
          "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 2 VECES AL MES",
        expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        features: [
          { icon: (small) => <BrandIcon src={brandList[2].logo} />, text: "10% OFF" },
        ],
      },
      {
        number: 3,
        benefit: "10% OFF + TOTE BAG",
        application: "CÓDIGO DE DESCUETNO",
        restrictions:
          "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 2 VECES AL MES TOTE BAG SOLO 1 VEZ",
        expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        features: [
          { icon: (small) => <BrandIcon src={brandList[2].logo} />, text: "10% OFF" },
          { icon: (small) => <BrandIcon src={brandList[2].logo} />, text: "TOTE BAG" },
        ],
      },
      {
        number: 4,
        benefit: "15% OFF EN SITIO WEB",
        application: "CÓDIGO DE DESCUETNO",
        restrictions:
          "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 2 VECES AL MES",
        expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        features: [
          { icon: (small) => <BrandIcon src={brandList[2].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 5,
        benefit: "BUY 1 GET 1 FREE",
        application: "CÓDIGO DE DESCUETNO",
        restrictions:
          "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 1 VEZ AL MES",
        expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 1 VEZ",
        features: [
          { icon: (small) => <BrandIcon src={brandList[2].logo} />, text: "FREE" },
        ],
      },
      {
        number: 6,
        benefit: "SET GRATIS",
        application: "CÓDIGO DE DESCUETNO",
        restrictions:
          "SUBIR STORY CON EL SET PUESTO Y TAGGEAR A LA MARCA EN INSTAGRAM.",
        expiration: "UNICA VEZ",
        features: [
          { icon: (small) => <BrandIcon src={brandList[2].logo} />, text: "FREE" },
        ],
      },

    ],
    instagram: "@feeleuphoria.wear",
  },
  {
    logo: "/img/brand-4.png",
    levels: [
      {
        number: 1,
        benefit: "10% OFF",
        application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
        restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
        expiration: "N/A",
        features: [
          { icon: (small) => <BrandIcon src={brandList[3].logo} />, text: "10% OFF" },
        ],
      },
      {
        number: 2,
        benefit: "15% OFF",
        application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
        restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
        expiration: "N/A",
        features: [
          { icon: (small) => <BrandIcon src={brandList[3].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 3,
        benefit: "20% OFF",
        application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
        restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
        expiration: "N/A",
        features: [
          { icon: (small) => <BrandIcon src={brandList[3].logo} />, text: "20% OFF" },
        ],
      },
      {
        number: 4,
        benefit: "20% OFF",
        application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
        restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
        expiration: "N/A",
        features: [
          { icon: (small) => <BrandIcon src={brandList[3].logo} />, text: "20% OFF" },
        ],
      },
      {
        number: 5,
        benefit: "UNA TANK TOP DE REGALO EN COMPRA DE 1,200 PESOS",
        application: "SOLO EN TIENDA, SUBIENDO STORY",
        restrictions: "NO SE PUEDE PAGINA WEB",
        expiration: "N/A",
        features: [
          { icon: (small) => <BrandIcon src={brandList[3].logo} />, text: "FREE" },
        ],
      },
      {
        number: 6,
        benefit: "GIFT CARD DE 600 PESOS",
        application: "SOLO EN TIENDA, SUBIENDO STORY",
        restrictions: "NO SE PUEDE PAGINA WEB",
        expiration: "N/A",
        features: [
          { icon: (small) => <BrandIcon src={brandList[3].logo} />, text: "GIFT CARD" },
        ],
      },
    ],
    instagram: "@mycrazyfit_",
  },
  {
    logo: "/img/brand-5.png",
    levels: [
      {
        number: 1,
        benefit: "10% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions:
          "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[4].logo} />, text: "10% OFF" },
        ],
      },
      {
        number: 2,
      },
      {
        number: 3,
        benefit: "15% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions:
          "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[4].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 4,
      },
      {
        number: 5,
        benefit: "20% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions:
          "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[4].logo} />, text: "20% OFF" },
        ],
      },
      {
        number: 6,
        benefit: "20% OFF",
        application: "MOSTRANDO UN CÓDIGO",
        restrictions:
          "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
        expiration: "1 SEMANA",
        features: [
          { icon: (small) => <BrandIcon src={brandList[4].logo} />, text: "20% OFF" },
        ],
      },
    ],
    instagram: "@BLOOMSUPERFOODS",
  },
  {
    levels: [
      {
        number: 1,
        benefit: "10% OFF",
        application: "CÓDIGO: TBM10",
        restrictions: "COMPRA MÍNIMA $800 PESOS",
        features: [
          { icon: (small) => <BrandIcon src={brandList[5].logo} />, text: "10% OFF" },
        ],
      },
      {
        number: 2,
        benefit: "10% OFF",
        application: "CÓDIGO: TBM10",
        restrictions: "COMPRA MÍNIMA $800 PESOS",
        features: [
          { icon: (small) => <BrandIcon src={brandList[5].logo} />, text: "10% OFF" },
        ],
      },
      {
        number: 3,
        benefit: "15% OFF",
        application: "CÓDIGO: 15TBM",
        restrictions: "COMPRA MÍNIMA $800 PESOS",
        features: [
          { icon: (small) => <BrandIcon src={brandList[5].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 4,
        benefit: "15% OFF",
        application: "CÓDIGO: 15TBM",
        restrictions: "COMPRA MÍNIMA $800 PESOS",
        features: [
          { icon: (small) => <BrandIcon src={brandList[5].logo} />, text: "15% OFF" },
        ],
      },
      {
        number: 5,
        benefit: "20% OFF",
        application: "WIN20TBM",
        restrictions: "COMPRA MÍNIMA $800 PESOS",
        features: [
          { icon: (small) => <BrandIcon src={brandList[5].logo} />, text: "20% OFF" },
        ],
      },
      {
        number: 6,
        benefit: "20% OFF",
        application: "WIN20TBM",
        restrictions: "COMPRA MÍNIMA $800 PESOS",
        features: [
          { icon: (small) => <BrandIcon src={brandList[5].logo} />, text: "20% OFF" },
        ],
      },
    ],
    instagram: "@Fiera_activewear",
  },
];

export default function BrandsFeaturesCarousel({
  selectedBrand,
  showCarousel,
  setShowCarousel,
}) {
  const options = {
    align: "start",
    loop: true,
    startIndex: selectedBrand?.index ? selectedBrand.index : 0,
  };
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  useEffect(() => {
    if (showCarousel) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showCarousel]);

  if (showCarousel) {
    return (
      <>
        <div
          className="rewards-brands__features"
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            zIndex: 1000,
          }}
        >
          <section className="carousel__brands-features">
            <div className="brands-features__viewport" ref={emblaRef}>
              <div id="rewardsModal" className="brands-features__container">
                {brandList.map((brand, index) => (
                  <BrandFeaturesTable
                    key={index}
                    currentBrand={brand}
                    emblaApi={emblaApi}
                    setShowCarousel={setShowCarousel}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
        <div className="rewards-brands__backdrop"></div>
      </>
    );
  }
  return null;
}
