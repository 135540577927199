import moment from "moment";
import React, { useEffect, useState } from "react";

const SelectWeekDays = ({ value, modifier, disabled }) => {
  const [repeatDays, setRepeatDays] = useState([]);

  useEffect(() => {
    if (value && value !== null) {
      if (!Array.isArray(value)) {
        const arrayValue = Array.from(value);
        if (Array.isArray(arrayValue)) setRepeatDays(arrayValue);
      } else {
        setRepeatDays(value);
      }
    }
  }, [repeatDays, value]);

  const toggleRepeatDay = (dayIndex) => {
    const current = [...repeatDays];
    const index = current.findIndex((day) => day === dayIndex);
    if (index === -1) current.push(dayIndex);
    else current.splice(index, 1);
    setRepeatDays(current);
    if (typeof modifier === "function") {
      modifier(current);
    }
  };

  return (
    <div className="row align-items-center mb-2">
      <div className="btn-group">
        {[1, 2, 3, 4, 5, 6, 7].map((dayIndex) => (
          <button
            key={dayIndex}
            type="button"
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              toggleRepeatDay(dayIndex);
            }}
            className={`btn btn-sm border btn-${
              repeatDays.includes(dayIndex) ? "primary" : "light text-uppercase"
            }`}
          >
            {moment(dayIndex === 7 ? 0 : dayIndex, "d").locale("es").format("dd")}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectWeekDays;
