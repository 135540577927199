import { Link } from "@reach/router";
import React, { useEffect, useRef, useState } from "react";
import "./LandingOnlineNavbar.css";
import tbmLogoBlanco from "../../assets/online/TBM-Logo-Blanco.svg";

export default function LandingOnlineNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollY = useRef(0); 

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setIsScrolled(currentScrollY > 50); // Cambia el color del fondo después de 50px de scroll


      if (currentScrollY > lastScrollY.current) {
        // Si el usuario baja, ocultamos el navbar
        setIsVisible(false);
      } else {
        // Si el usuario sube, mostramos el navbar
        setIsVisible(true);
      }

      lastScrollY.current = currentScrollY; // Actualizamos la referencia
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="navbar-landing-online"
      className={`navbar navbar-expand-lg navbar-light fixed-top hide-mobile shadow-none vw-100 `}
      style={{
        backgroundColor: isScrolled ? "#c6c6c6" : "transparent",
        transform: isVisible ? "translateY(0)" : "translateY(-100%)",
      }}
    >
      <div className="container-fluid px-0 navbar-container d-flex justify-content-between align-items-center position-relative animate-fade-in-and-translate">
        <a className="navbar-brand p-0" href="/#landing">
          <img
            style={{ width: "200px", height: "20px", maxWidth: "unset" }}
            src={tbmLogoBlanco}
            className="logo-navbar"
            alt="The Body Method"
          />
        </a>
        <button
          className="navbar-toggler bg-transparent border border-light position-absolute end-0 start-0 m-auto w-max-content"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars text-white "></i>
        </button>

        <div
          className="collapse navbar-collapse"
          style={{ width: "660px", maxWidth: "660px" }}
          id="navbarNav"
        >
          <div className="container-fluid px-0">
            <ul className="navbar-nav nav justify-content-between">
              <li className="nav-item nav-item-active h-max-content">
                <a className="nav-link mt-0 " href="/#about">
                  Disciplinas
                </a>
              </li>
              <li
                className={`nav-item h-max-content
                `}
              >
                <a className="nav-link mt-0" href="#scrollspyMetodo">
                  Metodo
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a className="nav-link mt-0" href="#scrollspyFunciones">
                  Funciones
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a className="nav-link mt-0" href="#scrollspyTestimonios">
                  Testimonios
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a className="nav-link mt-0" href="/paquetes/">
                  Paquetes
                </a>
              </li>
              <li className="nav-item h-max-content">
                <a className="nav-link mt-0" href="/rewards/">
                  Rewards
                </a>
              </li>
            </ul>
          </div>
        </div>

        <Link
          className="btn bold online-navbar__login-btn text-uppercase"
          to="/entrar"
        >
          <i className="fa fa-user"></i> <span className="ms-1">Ingresar</span>
        </Link>
      </div>
    </nav>
  );
}
