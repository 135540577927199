import React, { useEffect, useRef, useState } from "react";
import "./LandingOnlineHero.css";

export default function LandingOnlineHero() {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 100);
  }, []);

  return (
    <div id="onlineHero" className="container-fluid px-0">
      <div className="online-hero-overlay"></div>
      <video
        className="mw-100 w-100"
        autoPlay
        muted
        style={{ height: "600px", objectFit: "cover" }}
      >
        <source
          src={
            "https://thebodymethod.s3.us-west-1.amazonaws.com/hero-video-monterrey.mp4"
          }
          type="video/mp4"
        />
      </video>

      <div className="online-hero__content ">
        <h1  className={`online-hero__title ${
            showAnimation ? "animate-fade-in" : "opacity-0"
          }`}>
          Un espacio digital donde hay lugar para el cambio y el empoderamiento
        </h1>
        <p className={`online-hero__desc ${
            showAnimation ? "animate-fade-in" : "opacity-0"}`}>
          Conecta contigo y siéntete viva a través del ejercicio
        </p>

        <div    className={`content__ctas d-flex justify-content-between align-items-center ${
            showAnimation ? "animate-fade-in" : "opacity-0"
          }`}>
          <button className="btn online-hero-cta">
            PRUÉBALO 7 DÍAS GRATIS
          </button>
          <button className="btn online-hero-cta">
            CONOCE TU PLAN IDEAL
          </button>
        </div>
      </div>
    </div>
  );
}
