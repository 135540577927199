import React from "react";
import LandingOnline from "./LandingOnline";

const HomeOnline = () => {
  return (
    <div className="container-fluid px-0">
      <LandingOnline />
    </div>
  );
};

export default HomeOnline;
