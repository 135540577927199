import React, { useEffect, useRef, useState } from "react";
import "./LandingOnlineFavorites.css";
import FavoritesCarousel from "./FavoritesCarousel";

import favoritesBarre from "../../assets/online/favorites-barre.png";
import favoritesBooty from "../../assets/online/favorites-booty.png";
import favoritesChest from "../../assets/online/favorites-chest.png";
import favoritesPilates from "../../assets/online/favorites-pilates.png";
import favoritesPower from "../../assets/online/favorites-power.png";
import favoritesSpicyBarre from "../../assets/online/favorites-spicy-barre.png";
import favoritesSpicy from "../../assets/online/favorites-spicy.png";
import favoritesStrong from "../../assets/online/favorites-strong.png";
import favoritesSweat from "../../assets/online/favorites-sweat.png";
import favoritesTonedLegs from "../../assets/online/favorites-toned-legs.png";

export default function LandingOnlineFavorites() {
  const [gradientPosition, setGradientPosition] = useState(43);

  const containerRef = useRef(null);

  // Usamos IntersectionObserver para detectar la visibilidad
  useEffect(() => {
    const observerOptions = {
      root: null, // Usamos la ventana de visualización
      rootMargin: "0px",
      threshold: 0.3, // Al 10% del componente visible, se activa la animación
    };

    // Función para manejar la visibilidad del componente
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("opacity-0");
          entry.target.classList.add("animate-fade-in");
        }
      });
    };

    // Creamos el IntersectionObserver
    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    // Observamos los componentes
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // Limpiamos el observer cuando el componente se desmonte
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const percentage = 100 - (e.clientY / window.innerHeight) * 100;
      setGradientPosition(percentage);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  const slides = [
    favoritesBarre,
    favoritesBooty,
    favoritesChest,
    favoritesPilates,
    favoritesPower,
    favoritesSpicyBarre,
    favoritesSpicy,
    favoritesStrong,
    favoritesSweat,
    favoritesTonedLegs,
  ];

  return (
    <div className="online-favorites position-relative">
      <div
        style={{
          backgroundImage: `radial-gradient(at top right,#92b5d5 ${gradientPosition}%,#a22749 88%)`,
        }}
        className="favorites__background"
      ></div>

      <div className="opacity-0" ref={containerRef}>
        <h2 className="online-title favorites__title">
          Marca tus clases completadas y favoritas
        </h2>
        <p className="favorites__desc">
          Lleva un registro de tu progreso y accede fácilmente a tus rutinas
          preferidas.
        </p>
        <p className="favorites__desc">
          Mantente organizada y motivada con nuestras herramientas
          personalizadas.
        </p>

      </div>
        <FavoritesCarousel
          options={{ loop: true, duration: 30, align: "start" }}
          slides={slides}
        />

        <button type="button" className="btn favorites__btn">
          PRUÉBALO 7 DÍAS GRATIS
        </button>
    </div>
  );
}
