import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";
import moment from "moment";
import { getStartOfDay } from "../../utils/dates";
import VideoPropsService from "../../services/VideoPropsService";

const VideoCard = ({ video, agregarFavorito, eliminarFavorito }) => {
  const [showProps, setShowProps] = useState(false);
  const [videoProps, setVideoProps] = useState(null);

  const available_at = getStartOfDay(video.available_at);
  const today = getStartOfDay();

  useEffect(() => {
    if (showProps) {
      VideoPropsService.getVideoTypes(video.video_id).then((res) => {
        const { video_props } = res.data;
        setVideoProps(video_props);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProps]);

  const toggleProps = (e) => {
    e.stopPropagation();
    setShowProps(!showProps);
  };

  const handleClick = () => {
    if (video.available_at === null || available_at <= today) {
      navigate(`/mytbm/video/${video.video_id}`);
    }
  };

  const handleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (video.favorites.length > 0 || video.favorite === 1) {
      eliminarFavorito(video.video_id);
    } else {
      agregarFavorito(video.video_id);
    }
  };

  const renderLock = () => {
    if (video.available_at !== null) {
      if (available_at !== today && available_at > today) {
        return (
          <button className="btn btn-favorite btn-round btn-sm btn-light">
            <i className="fa fa-lock"></i>
          </button>
        );
      }
    }
  };

  const renderFavorite = () => {
    if (video.available_at === null || available_at <= today) {
      return (
        <button
          onClick={handleFavorite}
          className={`btn btn-favorite bg-${
            video.favorites.length > 0 ? "danger" : "light"
          }`}
        >
          <i className="fa fa-heart"></i>
        </button>
      );
    }
  };

  const renderTitle = () => {
    if (String(video.name).length > 35) {
      return String(video.name).substring(0, 35) + "...";
    }
    return video.name;
  };

  const renderDescription = () => {
    if (String(video.description).length > 60) {
      return video.description.substring(0, 60) + "...";
    }
    return video.description;
  };

  const renderInstructorName = () => {
    if (video.instructor && video.instructor !== null) {
      return (
        <p className="text-end mb-0">
          {video.instructor.name} {video.instructor.last_name}{" "}
          <i className="fa fa-user-circle ms-2"></i>
        </p>
      );
    }
  };

  const renderCompleted = () => {
    if (video.completed_videos.length > 0) {
      return (
        <span className="span-completed bg-success me-3">
          <i className="fa fa-check me-2"></i>
        </span>
      );
    }
  };

  const renderBadges = () => {
    const badges = [];
    if (Array.isArray(video.tags)) {
      video.tags.forEach((tag) => {
        badges.push(
          <span
            key={tag.tag_id}
            className="badge badge-pill bg-light text-dark me-2"
          >
            {tag.name}
          </span>
        );
      });
    }
    return badges;
  };

  const renderPropsButton = () => {
    if (Array.isArray(video.video_props) && video.video_props.length > 0) {
      return (
        <button
          onClick={toggleProps}
          className="badge-props btn btn-sm btn-primary"
        >
          <i className={`fa fa-${showProps ? "times" : "dumbbell"} me-1`} />{" "}
          Props
        </button>
      );
    }
  };

  const renderPropsList = () => {
    if (showProps)
      return (
        <div className="card no-scale p-3 text-dark props-list">
          {!Array.isArray(videoProps) ? (
            <div className="spinner-border spinner-border-sm" />
          ) : (
            <ul className="ps-3 mb-0">
              {videoProps.map(({ prop }) => (
                <li className="small">
                  {prop.name} {!prop.required ? "(opcional)" : ""}
                </li>
              ))}
            </ul>
          )}
        </div>
      );
  };

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 position-relative">
      <div
        onClick={handleClick}
        className="text-dark no-decoration"
        style={{ zIndex: 0 }}
      >
        <span className="card-video-date text-white">
          {moment(
            video.available_at !== null ? video.available_at : video.createdAt
          ).format("DD MMM YYYY")}
        </span>
        {renderCompleted()}
        {renderLock()}
        {renderFavorite()}
        <div className="card bg-black text-white shadow-sm no-scale px-0 py-0 card-video">
          <div className="video-thumbnail-container">
            <img
              className="video-thumbnail"
              src={
                video.file !== null
                  ? `${S3_ENDPOINT}/${video.file.name}.${video.file.type}`
                  : `${S3_ENDPOINT}/thebodymethod-logo.jpeg`
              }
              alt="video thumbnail"
            />
            {renderPropsButton()}
            {renderPropsList()}
          </div>
          <div className="container-fluid p-3">
            <h3 className="mb-0">{renderTitle()}</h3>
            <p className="mb-1">{renderDescription()}</p>
            <div className="row align-items-center">
              <div className="col-6">
                <div className="badges-container">
                  <span className="badge badge-pill bg-primary-light text-primary me-2">
                    <i className="fa fa-clock me-1" /> {video.duration}
                  </span>
                  {renderBadges()}
                </div>
              </div>
              <div className="col-6">{renderInstructorName()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
